<script setup lang="ts">
const { t } = useI18n()
import { formatDistance } from 'date-fns'
import { getDateFnsLocaleFromBrowserLanguage } from '~/utils/formatter'
import { apiStore } from '~/stores/api'
import type { ToastList } from '~/types/toast'
import { debounce } from 'lodash'
import { Entity } from '~/types/view-elements'

const api = apiStore().getApiClient
const router = useRouter()
const route = useRoute()

const state = reactive({
  suppliers: [],
  isLoading: false,
  currentPage: 1,
  perPage: 15,
  hasFetchingNext: false,
  observer: null,
  supportedIntersectObserver: false,
  isReachedAtLast: false,
  hasAddSupplierModalOpen: false,
  hasAddingSupplier: false,
  hasDeleteSupplierModalOpen: false,
  isDeletingSupplier: false,
  search: '',
  status: route.query.status || '',
  toast: inject('toast') as ToastList,
  selectedSuppliersForDelete: [],
  imagePath: null,
  isFullscreenImageModalOpen: false,
  canCreateSupplier: false,
  selectAll: false,
})

// Observer & Un-observe
function registerObserver() {
  let options = {
    rootMargin: '0px',
    root: null,
  }

  state.observer = new IntersectionObserver((entries, _) => {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0) {
        loadPaginatedData(state.currentPage, state.perPage)
      }
    })
  }, options)

  let target = document.querySelector('#issueObserver')
  if (target) {
    state.observer.observe(target)
  }
}

function unRegisterObserver() {
  let target = document.querySelector('#issueObserver')
  if (target && state.observer) {
    state.observer.unobserve(target)
    state.observer = null
  }
}

async function loadPaginatedData(page: number = 1) {
  if (state.hasFetchingNext || state.isReachedAtLast) return

  state.isLoading = true
  state.hasFetchingNext = true

  api
    .getSuppliers(page, state.perPage, {
      name: state.search,
      status: state.status,
    })
    .then((response: { data: []; meta: {} }) => {
      if (response?.data) {
        const d = response?.data.map(d => {
          if (state.selectAll) {
            state.selectedSuppliersForDelete.push(d.id)
          }
          Object.assign(d, { is_checked: state.selectAll })
          return d
        })
        state.suppliers.push(...d)

        if (response.meta.current_page == response.meta.last_page) state.isReachedAtLast = true

        if (response?.auth?.can?.create) state.canCreateSupplier = response?.auth?.can?.create

        state.currentPage += 1
        state.hasFetchingNext = false
      }
      state.isLoading = false
    })
    .catch(error => {
      router.push('/')
      state.toast.error(t('global.error'), error.response?.data?.message || error.toString())
    })
}

function goToSupplierDetails(row: any) {
  if (!row.auth.can.view) {
    return
  }

  router.push(`/sourcing/${row.id}`)
}

function openAddSupplierModal() {
  state.hasAddSupplierModalOpen = true
}

function closeAddSupplierModal() {
  state.hasAddSupplierModalOpen = false
}

function createSupplier(data: Entity) {
  state.suppliers.unshift(data)
}

const searchTextChanged = debounce(async e => {
  state.currentPage = 1
  state.isReachedAtLast = false
  state.suppliers = []
  state.selectAll = false
  state.selectedSuppliersForDelete = []

  await loadPaginatedData(1)
}, 400)

function appendOrRemoveForDelete(supplier: any) {
  supplier.is_checked = !supplier.is_checked
  if (supplier.is_checked) {
    state.selectedSuppliersForDelete.push(supplier)
  } else {
    const idx = state.selectedSuppliersForDelete.findIndex(e => e.id === supplier.id)
    state.selectedSuppliersForDelete.splice(idx, 1)
  }
}

function openDeleteSupplierModal() {
  state.hasDeleteSupplierModalOpen = true
}

function closeDeleteSupplierModal() {
  state.hasDeleteSupplierModalOpen = false
}

async function deleteSuppliers() {
  try {
    state.isDeletingSupplier = true
    let arrayIdx = 0
    for (const supplierId of state.selectedSuppliersForDelete) {
      const idx = state.suppliers.findIndex(e => e.id === supplierId)
      if (idx !== -1) {
        await api.deleteSupplier(supplierId)
        state.suppliers.splice(idx, 1)
      }
      arrayIdx += 1
    }

    state.selectedSuppliersForDelete = []
    state.selectAll = false

    state.toast.success(t('global.success'), t('suppliers.delete_success'))
    closeDeleteSupplierModal()
  } catch (error: any) {
    state.toast.error(t('global.error'), error.response?.data?.message || error.toString())
  } finally {
    state.isDeletingSupplier = false
  }
}

function openFullScreenImageModal(imagePath: string) {
  if (!imagePath) {
    return
  }

  state.imagePath = imagePath
  state.isFullscreenImageModalOpen = true
}

function closeFullScreenImageModal() {
  state.isFullscreenImageModalOpen = false
}

function selectOrUnselectSuppliers() {
  if (!state.selectAll && state.selectedSuppliersForDelete.length > 0) {
    state.selectAll = false
  } else {
    if (!state.selectAll && state.selectedSuppliersForDelete.length === 0) {
      state.selectAll = true
    } else {
      if (state.selectAll) {
        state.selectAll = false
      } else {
        state.selectAll = true
      }
    }
  }

  state.suppliers.forEach(supplier => {
    supplier.is_checked = state.selectAll
    if (supplier.is_checked) {
      state.selectedSuppliersForDelete.push(supplier.id)
    } else {
      state.selectedSuppliersForDelete = []
    }
  })
}

/** Permission functions start */
const canCreateSupplier = computed(() => {
  return state.canCreateSupplier
})
/** Permission functions end */

onMounted(async () => {
  if (!window.IntersectionObserver) {
    state.supportedIntersectObserver = false
  } else {
    state.supportedIntersectObserver = true
  }

  await loadPaginatedData(1)

  window.addEventListener('keyup', e => {
    if (e.key === 'Escape') {
      closeAddSupplierModal()
    }
  })
})

watch(
  () => state.suppliers.length,
  newVal => {
    registerObserver()
  },
)

watch(
  () => state.isReachedAtLast,
  newVal => {
    if (newVal) {
      unRegisterObserver()
    }
  },
)
</script>

<template>
  <div>
    <div class="w-full flex items-center justify-between">
      <div class="flex items-center relative">
        <OSearchBar
          v-model="state.search"
          :placeholder="`${$t('global.search')}`"
          class="!w-96"
          @update:modelValue="searchTextChanged"
        />
      </div>
      <div class="flex">
        <button
          class="btn-danger flex items-center gap-2 first:mr-2"
          v-if="state.selectedSuppliersForDelete.length > 0"
          @click="openDeleteSupplierModal"
        >
          <div i="carbon-trash-can" />
          {{ $t('global.delete') }}
        </button>
        <div v-if="state.suppliers.length > 0" class="mr-2">
          <button
            class="btn-secondary gap-1.5 h-full"
            data-test-id="select-unselect-entities"
            @click="selectOrUnselectSuppliers"
          >
            <div v-if="state.selectedSuppliersForDelete.length <= 0" class="flex items-center">
              <div i="carbon-checkbox" />
              <div class="pl-2">{{ $t('global.select') }}</div>
            </div>
            <div v-else class="flex items-center">
              <div i="carbon-checkbox-indeterminate" />
              <div class="pl-2">{{ $t('global.unselect') }}</div>
            </div>
          </button>
        </div>
        <button class="btn-primary h-full" @click="openAddSupplierModal" v-if="canCreateSupplier">
          <div i="carbon-intent-request-create" />
          {{ $t('global.new') }}
        </button>
      </div>
    </div>
    <div class="mt-8">
      <!-- Table -->
      <div class="grid grid-cols-13 px-2.5 gap-1 text-[#475467] text-center text-sm">
        <div class="col-span-1">
          <button class="text-left opacity-30" @click="selectOrUnselectSuppliers" v-if="state.suppliers.length > 0">
            <div v-if="state.selectedSuppliersForDelete.length > 0" i="carbon-checkbox-indeterminate" />
            <div v-else i="carbon-checkbox" />
          </button>
        </div>
        <div class="flex gap-2 col-span-3">
          <div scope="col" class="text-left w-10">{{ $t('global.type') }}</div>
          <div scope="col" class="text-left">{{ t('global.name') }}</div>
        </div>
        <div scope="col" class="text-left col-span-1">{{ t('global.image') }}</div>
        <div scope="col" class="text-left col-span-1">{{ t('global.status') }}</div>
        <div scope="col" class="text-left col-span-4">{{ t('global.description') }}</div>
        <div scope="col" class="text-left col-span-2">{{ t('global.last_updated') }}</div>
        <div scope="col">{{ t('global.action', 2) }}</div>
      </div>
      <div class="flex flex-col mt-4">
        <template v-for="(supplier, index) in state.suppliers" :key="supplier.uuid">
          <button
            :class="{ 'cursor-not-allowed': !supplier.auth.can.view }"
            class="group grid grid-cols-13 items-center gap-1 text-[#344054] text-center text-sm bg-white hover:bg-gray-50 border border-[#F2F4F7] not-first:border-t-0 first:rounded-t last:rounded-b px-2 py-1"
          >
            <div class="col-span-1 flex justify-center">
              <button @click="appendOrRemoveForDelete(supplier)" v-if="supplier.auth.can.delete">
                <div i="carbon-checkbox-checked-filled" class="text-[#3B82F6]" v-if="supplier.is_checked" />
                <div i="carbon-checkbox" class="opacity-40" v-else></div>
              </button>
            </div>
            <div @click="goToSupplierDetails(supplier)" class="flex items-center gap-2 col-span-3">
              <EntityIcon :entity="supplier" class="group-hover:bg-[#DEE9FF]" :tooltip="true" />
              <div class="text-left text-[#1D2939] text-sm break-after-all truncate" :title="supplier.name">
                {{ supplier.name }}
              </div>
            </div>

            <div class="col-span-1 max-h-10 overflow-hidden rounded" @click="openFullScreenImageModal(supplier?.image)">
              <img :src="supplier.image" v-if="supplier?.image" class="object-contain w-[42px] h-auto aspect-square" />
            </div>

            <div @click="goToSupplierDetails(supplier)" class="flex items-center gap-2 col-span-1">
              <PublishingStatusTag :status="supplier.status" />
            </div>

            <div @click="goToSupplierDetails(supplier)" class="flex items-center gap-2 col-span-4">
              <div
                class="text-left text-sm text-[#475467] line-clamp-2 truncate max-w-90 text-overflow-ellipsis inline-block"
                :title="supplier.description"
              >
                {{ supplier.description }}
              </div>
            </div>

            <div class="text-sm whitespace-nowrap col-span-2 text-left">
              <template v-if="supplier.updated_at">
                {{
                  formatDistance(new Date(supplier.updated_at), new Date(), {
                    addSuffix: true,
                    locale: getDateFnsLocaleFromBrowserLanguage(),
                  })
                }}
              </template>
            </div>

            <button
              class="p-1 mx-auto border border-transparent text-sm text-[#475467] transition hover:border-[#E6E6E5] col-span-1"
              @click.stop.prevent="goToSupplierDetails(supplier)"
            >
              <div i="carbon-arrow-up-right" />
            </button>
          </button>
        </template>
      </div>
      <div id="issueObserver" />
      <div v-if="state.isLoading" class="my-10">
        <Loader />
      </div>
      <div v-if="!state.isLoading && state.suppliers.length <= 0">
        <div class="text-center mt-8 text-black">
          {{ t('entities.no_suppliers') }}
        </div>
      </div>
    </div>
    <SupplierCreationModal
      :open="state.hasAddSupplierModalOpen"
      default-type="Project"
      @close="closeAddSupplierModal"
      @create="createSupplier"
    />

    <!-- Delete Modal -->
    <OModal :open="state.hasDeleteSupplierModalOpen">
      <template #content>
        <div class="flex flex-col gap-1">
          <h2 class="text-xl font-semibold">
            {{ $t('global.confirm_delete') }}
          </h2>
          <p>
            {{ $t('suppliers.delete_title') }}
          </p>
          <span
            class="mt-4 rounded-md bg-red-50 px-2 py-1 text-s font-medium text-red-700 ring-1 ring-inset ring-red-600/10"
          >
            <b>Warning:&nbsp;</b>{{ $t('suppliers.delete_description') }}
          </span>
        </div>
      </template>

      <template #footer>
        <div class="flex justify-end w-full gap-4">
          <button class="btn-secondary" :disabled="state.isDeletingSupplier" @click="closeDeleteSupplierModal">
            {{ t('global.cancel') }}
          </button>
          <button
            class="btn-danger bg-red-500 text-white"
            autofocus
            :disabled="state.isDeletingSupplier"
            @click="deleteSuppliers"
          >
            {{ state.isDeletingSupplier ? t('global.deleting') : t('global.delete') }}
          </button>
        </div>
      </template>
    </OModal>

    <!-- Image modal -->
    <OModal :open="state.isFullscreenImageModalOpen" @close="closeFullScreenImageModal">
      <template #content>
        <img :src="state.imagePath" />
      </template>

      <template #footer>
        <div class="flex justify-end w-full gap-4">
          <button class="btn-primary w-full" @click="closeFullScreenImageModal">
            {{ $t('global.close') }}
          </button>
        </div>
      </template>
    </OModal>
  </div>
</template>
