import _ from 'lodash'

export const formkitTheme: any = {
  // Global styles apply to _all_ inputs with matching section keys
  global: {
    fieldset: 'w-full border border-[#C0C0BF] rounded px-2 pb-1',
    help: 'text-xs text-gray-400 italic',
    inner:
      'relative focus-within:ring-[#373FEF] focus-within:ring-2 formkit-disabled:bg-gray-200 formkit-disabled:cursor-not-allowed formkit-disabled:pointer-events-none',
    input:
      'rounded appearance-none bg-transparent placeholder:text-[#C0C0BF] focus:outline-none focus:ring-0 focus:shadow-none',
    label: 'block mb-1.5 text-[#404040] text-left',
    legend: 'font-bold text-sm',
    loaderIcon: 'inline-flex items-center w-4 text-gray-600 animate-spin',
    message: 'text-[#373FEF]-500 mb-1 text-xs italic',
    messages: 'list-none p-0 mt-1 mb-0',
    outer: 'formkit-disabled:opacity-50',
    prefixIcon:
      'w-10 flex self-stretch grow-0 shrink-0 rounded-tl rounded-bl border-r border-[#C0C0BF] bg-white bg-gradient-to-b from-transparent to-gray-200 cursor-pointer [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto [&>svg]:hover:text-secondary',
    suffixIcon:
      'absolute right-0 top-1/2 -translate-y-1/2 w-7 mr-1 flex self-stretch grow-0 shrink-0 cursor-pointer [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto [&>svg]:hover:text-secondary',
  },

  // Family styles apply to all inputs that share a common family
  'family:box': {
    decorator:
      'block relative h-4 w-4 rounded bg-white ring-1 ring-[#E6E6E5] peer-checked:ring-[#373FEF] text-transparent peer-checked:text-secondary',
    decoratorIcon: 'flex p-0.5 w-full h-full absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2',
    help: 'mb-1.5 mt-1.5',
    input: 'fixed left-[999em] w-0 h-0 overflow-hidden opacity-0 pointer-events-none peer',
    label: '$reset text-sm text-[#404040] select-none',
    wrapper: 'flex items-center gap-2',
    outer: '$reset flex flex-col',
  },
  'family:button': {
    input: '$reset inline-flex items-center bg-[#373FEF] text-white text-sm font-normal py-3 px-6 rounded',
    wrapper: 'mb-1',
    prefixIcon: '$reset block w-4 -ml-2 mr-2 stretch',
    suffixIcon: '$reset block w-4 ml-2 stretch',
  },
  'family:dropdown': {
    dropdownWrapper: 'my-2 w-full drop-shadow-lg rounded [&::-webkit-scrollbar]:hidden',
    inner:
      'relative flex ring-1 ring-[#E6E6E5] focus-within:ring-2 rounded mb-1 formkit-disabled:focus-within:ring-[#E6E6E5] formkit-disabled:focus-within:ring-1 [&>span:first-child]:focus-within:text-secondary',
    input: 'w-full px-3 py-2',
    listbox: 'bg-white drop-shadow-lg rounded overflow-hidden',
    listboxButton: 'flex w-12 self-stretch justify-center mx-auto',
    listitem:
      'pl-7 relative hover:bg-gray-300 data-[is-active="true"]:bg-gray-300 data-[is-active="true"]:aria-selected:bg-[#373FEF] aria-selected:bg-[#373FEF] aria-selected:text-white',
    loaderIcon: 'ml-auto',
    loadMoreInner:
      'flex items-center justify-center text-sm p-2 text-center w-full text-secondary formkit-loading:text-gray-500 cursor-pointer [&>span]:mr-3 [&>span]:ml-0',
    option: 'px-3 py-2',
    optionLoading: 'text-gray-500',
    placeholder: 'px-3 py-2 text-[#C0C0BF]',
    selector: 'flex w-full justify-between items-center [&u]',
    selectedIcon: 'block absolute top-1/2 left-2 w-3 -translate-y-1/2',
    selectIcon: 'flex box-content w-4 px-2 self-stretch grow-0 shrink-0',
  },
  'family:text': {
    inner:
      'flex items-center ring-1 ring-[#E6E6E5] focus-within:ring-2 [&>label:first-child]:focus-within:text-secondary rounded mb-1',
    input: 'w-full px-3 py-2 border-none text-base text-[#404040] placeholder-[#C0C0BF]',
  },

  // Specific styles apply only to a given input type
  color: {
    inner:
      'flex max-w-[5.5em] w-full formkit-prefix-icon:max-w-[7.5em] formkit-suffix-icon:formkit-prefix-icon:max-w-[10em]',
    input:
      '$reset appearance-none w-full cursor-pointer border-none rounded p-0 m-0 bg-transparent [&::-webkit-color-swatch-wrapper]:p-0 [&::-webkit-color-swatch]:border-none',
    suffixIcon: 'min-w-[2.5em] pr-0 pl-0 m-auto',
  },
  file: {
    fileItem: 'flex items-center text-gray-800 mb-1 last:mb-0',
    fileItemIcon: 'w-4 mr-2 shrink-0',
    fileList: 'shrink grow peer px-3 py-2 formkit-multiple:data-[has-multiple="true"]:mb-6',
    fileName: 'break-all grow text-ellipsis',
    fileRemove:
      'relative z-[2] ml-auto text-[0px] hover:text-[#373FEF]-500 pl-2 peer-data-[has-multiple=true]:text-sm peer-data-[has-multiple=true]:text-secondary peer-data-[has-multiple=true]:ml-3 peer-data-[has-multiple=true]:mb-1.5 formkit-multiple:bottom-[0.15em] formkit-multiple:pl-0 formkit-multiple:ml-0 formkit-multiple:left-[1em] formkit-multiple:formkit-prefix-icon:left-[3.75em]',
    fileRemoveIcon: 'block text-base w-3 relative z-[2]',
    inner: 'relative flex cursor-pointer formkit-multiple:[&>button]:absolute',
    input: 'cursor-pointer text-transparent absolute top-0 right-0 left-0 bottom-0 opacity-0 z-[2]',
    noFiles: 'flex w-full items-center px-3 py-2 text-[#C0C0BF]',
    noFilesIcon: 'w-4 mr-2',
  },
  radio: {
    decorator: 'rounded-full',
    decoratorIcon: 'w-5 p-[5px]',
  },
  range: {
    inner: '$reset flex items-center',
    input: '$reset w-full mb-1 h-2 p-0 rounded-full',
    prefixIcon:
      '$reset w-4 mr-1 flex self-stretch grow-0 shrink-0 [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
    suffixIcon:
      '$reset w-4 ml-1 flex self-stretch grow-0 shrink-0 [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
  },
  select: {
    inner:
      'flex relative items-center rounded mb-1 ring-1 ring-[#E6E6E5] focus-within:ring-2 [&>span:first-child]:focus-within:text-secondary',
    input:
      'w-full pl-3 pr-8 py-2 border-none text-base text-[#404040] placeholder-[#C0C0BF] formkit-multiple:p-0 data-[placeholder="true"]:text-[#C0C0BF] formkit-multiple:data-[placeholder="true"]:text-inherit',
    selectIcon: 'flex p-[3px] shrink-0 w-5 mr-2 -ml-[1.5em] h-full pointer-events-none',
    option: 'formkit-multiple:p-3 formkit-multiple:text-sm text-[#404040]',
    suffixIcon: '-z-1',
  },
  textarea: {
    inner: 'flex rounded mb-1 ring-1 ring-[#E6E6E5] [&>label:first-child]:focus-within:text-secondary',
    input: 'block w-full px-3 py-3 border-none text-base text-[#404040] placeholder-[#C0C0BF] focus:shadow-outline',
  },

  // PRO input styles
  autocomplete: {
    closeIcon: 'block grow-0 shrink-0 w-3 mr-3.5',
    inner: '[&>div>[data-value]]:absolute [&>div>[data-value]]:mb-0',
    option: 'grow text-ellipsis',
    selection: 'static flex left-0 top-0 right-0 bottom-0 mt-0 mb-1.5 rounded bg-[#373FEF] text-white',
    selections: 'flex flex-wrap gap-2 mt-2',
    suffixIcon: '-z-1',
  },
  mask: {
    inner:
      'flex items-center ring-1 ring-[#E6E6E5] focus-within:ring-2 [&>label:first-child]:focus-within:text-secondary rounded mb-1',
    input: 'w-full px-3 py-2 border-none text-base text-[#404040] placeholder-[#C0C0BF]',
  },
  rating: {
    inner: 'relative flex items-center w-[8em] formkit-disabled:bg-transparent',
    itemsWrapper: 'w-full',
    onItems: 'text-yellow-400',
    onItemWrapper: '[&>*]:w-full [&>svg]:h-auto [&>svg]:max-w-none [&>svg]:max-h-none',
    offItems: 'text-gray-500',
    offItemWrapper: '[&>*]:w-full [&>svg]:h-auto [&>svg]:max-w-none [&>svg]:max-h-none',
  },
  repeater: {
    content: 'grow p-3 flex flex-col align-center',
    controlLabel: 'absolute opacity-0 pointer-events-none',
    controls: 'flex flex-col items-center justify-center bg-gray-100 p-3',
    downControl: 'hover:text-secondary disabled:hover:text-inherit disabled:opacity-25',
    fieldset: 'py-4 px-5',
    help: 'mb-1.5 mt-1.5',
    item: 'flex w-full mb-1 rounded border border-gray-200',
    moveDownIcon: 'block w-3 my-1',
    moveUpIcon: 'block w-3 my-1',
    removeControl: 'hover:text-secondary disabled:hover:text-inherit disabled:opacity-25',
    removeIcon: 'block w-5 my-1',
    upControl: 'hover:text-secondary disabled:hover:text-inherit disabled:opacity-25',
  },
  taglist: {
    input:
      '$reset formkit-input flex w-full rounded appearance-none bg-transparent placeholder:text-[#C0C0BF] focus:outline-none focus:ring-0 focus:shadow-none w-full grow',
    removeSelection: 'w-2.5 mx-1 self-center text-white leading-none',
    tag: 'flex items-center pl-2 pr-1.5 py-1 bg-[#373FEF] text-white text-xs rounded-full',
    tagWrapper:
      'flex gap-1.5 focus:outline-none focus:text-white [&>div]:focus:bg-[#373FEF] [&>div>button]:focus:text-white',
    tagLabel: 'pr-1',
    tags: 'flex flex-wrap items-center gap-1 w-full py-2 px-3',
    suffixIcon: '-z-1',
  },
  toggle: {
    altLabel: 'block w-full mb-1',
    inner: '$reset inline-block mr-2 scale-90 cursor-pointer',
    input: 'peer fixed left-[999em] opacity-0 pointer-events-none',
    innerLabel: 'text-[10px] absolute left-full top-1/2 -translate-x-full -translate-y-1/2 px-1',
    thumb: 'relative left-0 aspect-square rounded-full transition-all w-5 bg-gray-100',
    track:
      'p-0.5 min-w-[3em] relative rounded-full transition-all bg-[#C0C0BF] peer-checked:bg-[#373FEF] peer-checked:[&>div:last-child]:left-full peer-checked:[&>div:last-child]:-translate-x-full peer-checked:[&>div:first-child:not(:last-child)]:left-0 peer-checked:[&>div:first-child:not(:last-child)]:translate-x-0',
    valueLabel: 'text-sm',
    wrapper: 'flex flex-wrap items-center gap-1',
  },
}

export const formkitThemeClassesAsArray = _.chain(formkitTheme)
  .values()
  .map(_.values)
  .flatten()
  .join(' ')
  .split(' ')
  .uniq()
  .value()
