<script setup lang="ts">
const { t } = useI18n()
import { formatDistance } from 'date-fns'
import { getDateFnsLocaleFromBrowserLanguage } from '~/utils/formatter'
import { apiStore } from '~/stores/api'
import { userStore } from '~/stores/user'
import type { ToastList } from '~/types/toast'
import { debounce } from 'lodash'
import { Status } from '~/utils/entity'

import { isForvia } from '~/helpers/growthbook/growthbook'
import { growthBookKey } from '~/modules/growthbook'
import { configStore } from '~/stores/configs'
import { viewsStore } from '~/stores/views'
import BomStatusFilter from './BomStatusFilter.vue'
import { useKeyupHandler } from '~/composables/useKeyupHandler'
import { Entity } from '~/types/view-elements'
import { entityTypesStore } from '~/stores/entity_types'

const growthBookInjectable = inject(growthBookKey)

const api = apiStore().getApiClient
const router = useRouter()
const route = useRoute()

const state = reactive({
  boms: [] as Entity[],
  isLoading: false,
  currentPage: 1,
  perPage: 15,
  hasFetchingNext: false,
  observer: null as IntersectionObserver | null,
  supportedIntersectObserver: false,
  isReachedAtLast: false,
  hasAddBomModalOpen: false,
  hasAddingBom: false,
  search: '',
  status: route.query.status || '',
  toast: inject('toast') as ToastList,
  selectedBomsForDelete: [] as Entity[],
  hasDeleteBomModalOpen: false,
  isDeleting: false,
  isImportModalOpen: false,
  isForvia: false,
  imagePath: null as null | string,
  isFullscreenImageModalOpen: false,
  selectAll: false,
})

const canCreateBom = computed(() => {
  return userStore()?.user?.roles.includes('admin') || userStore()?.user?.roles.includes('project_manager')
})

// Observer & Un-observe
function registerObserver() {
  let options = {
    rootMargin: '0px',
    root: null,
  }

  state.observer = new IntersectionObserver((entries, _) => {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0) {
        loadPaginatedData(state.currentPage)
      }
    })
  }, options)

  let target = document.querySelector('#issueObserver')
  if (target) {
    state.observer.observe(target)
  }
}

function unRegisterObserver() {
  let target = document.querySelector('#issueObserver')
  if (target && state.observer) {
    state.observer.unobserve(target)
    state.observer = null
  }
}

async function loadPaginatedData(page: number = 1) {
  if (state.hasFetchingNext || state.isReachedAtLast) return

  state.isLoading = true
  state.hasFetchingNext = true

  try {
    const [response]: [{ data: []; meta: { current_page: number; last_page: number } }, ...unknown[]] =
      await Promise.all([
        await api.getBoms(page, state.perPage, {
          name: state.search,
          status: state.status,
        }),
        viewsStore().loadViews(),
        entityTypesStore().loadEntityTypes(),
      ])
    const d = response?.data.map(d => {
      Object.assign(d, { is_checked: state.selectAll })
      return d
    })

    state.boms.push(...d)

    if (response.meta.current_page == response.meta.last_page) state.isReachedAtLast = true

    if (state.boms.length <= 0) {
      state.selectedBomsForDelete = []
      state.selectAll = false
    }

    state.currentPage += 1
    state.hasFetchingNext = false
  } catch (error) {
    router.push('/')
    state.toast.error(t('global.error'), error.response?.data?.message || error.toString())
  } finally {
    state.isLoading = false
  }
}

// function setMockupBomList() {
//   state.selectedBomsForDelete = []
//   state.selectAll = false
//   state.isLoading = false

//   state.boms = mockupJsonBomList.data as unknown as Entity[]
// }

function goToBomDetails(row: any) {
  if (!row.auth.can.view) return

  const view_id = route.query.view_id || configStore().getClientDefaultView
  const view_type =
    (route.query.view_type as 'tree' | 'graph' | 'table') || configStore().getClientDefaultViewDisplayType
  viewsStore().setDisplayType(view_type)

  router.push({
    path: `/boms/${row.id}`,
    query: {
      ...route.query,
      view_id,
      view_type,
      bom_tab: 1,
    },
  })
}

function openAddBomModal() {
  state.hasAddBomModalOpen = true
}

function closeAddBomModal() {
  state.hasAddBomModalOpen = false
}

function createBom(data: Entity) {
  state.boms.unshift(data)
}

const searchTextChanged = debounce(async e => {
  state.currentPage = 1
  state.isReachedAtLast = false
  state.boms = []
  state.selectAll = false
  state.selectedBomsForDelete = []
  await loadPaginatedData(1)
}, 400)

async function filterStatusChanged(status: string) {
  state.status = status
  state.currentPage = 1
  state.isReachedAtLast = false
  state.boms = []
  state.selectAll = false
  state.selectedBomsForDelete = []
  await loadPaginatedData(1)
}

function appendOrRemoveForDelete(bom: any) {
  bom.is_checked = !bom.is_checked
  if (bom.is_checked) {
    state.selectedBomsForDelete.push(bom)
  } else {
    const idx = state.selectedBomsForDelete.findIndex(e => e.id === bom.id)
    if (idx !== -1) {
      state.selectedBomsForDelete.splice(idx, 1)
    }
  }
}

function openDeleteBomModal() {
  state.hasDeleteBomModalOpen = true
}

function closeDeleteBomModal() {
  state.hasDeleteBomModalOpen = false
}

async function deleteBoms() {
  try {
    state.isDeleting = true
    let arrayIdx = 0

    for (const bom of state.selectedBomsForDelete) {
      const idx = state.boms.findIndex(e => e.id === bom.id)

      if (idx !== -1) {
        await api.removeBom(bom.id)
        state.boms.splice(idx, 1)
      }
      arrayIdx += 1
    }
    state.selectedBomsForDelete = []

    state.toast.success(t('global.success'), t('boms.delete_success'))
    closeDeleteBomModal()
  } catch (error: any) {
    state.toast.error(t('global.error'), error.response?.data?.message || error.toString())
  } finally {
    state.isDeleting = false
  }
}

function openImportModal() {
  state.isImportModalOpen = true
}

function closeImportModal() {
  state.isImportModalOpen = false
}

function openFullScreenImageModal(imagePath: string) {
  if (!imagePath) {
    return
  }

  state.imagePath = imagePath
  state.isFullscreenImageModalOpen = true
}

function closeFullScreenImageModal() {
  state.isFullscreenImageModalOpen = false
}

function selectOrUnselectAllBoms() {
  if (!state.selectAll && state.selectedBomsForDelete.length > 0) {
    state.selectAll = false
  } else {
    if (!state.selectAll && state.selectedBomsForDelete.length === 0) {
      state.selectAll = true
    } else {
      if (state.selectAll) {
        state.selectAll = false
      } else {
        state.selectAll = true
      }
    }
  }

  state.boms.forEach(bom => {
    bom.is_checked = state.selectAll
    if (bom.is_checked) {
      state.selectedBomsForDelete.push(bom)
    } else {
      state.selectedBomsForDelete = []
    }
  })
}

onBeforeMount(async () => {
  const growthBook = await growthBookInjectable?.init()
  state.isForvia = (await isForvia(growthBook)) || false
})

const handleEscapeKey = () => {
  closeAddBomModal()
  closeDeleteBomModal()
}

useKeyupHandler(handleEscapeKey)

onMounted(async () => {
  if (!window.IntersectionObserver) {
    state.supportedIntersectObserver = false
  } else {
    state.supportedIntersectObserver = true
  }

  await loadPaginatedData(1)
})

watch(
  () => state.boms.length,
  newVal => {
    if (!state.supportedIntersectObserver) return
    registerObserver()
  },
)

watch(
  () => state.isReachedAtLast,
  newVal => {
    if (newVal && state.supportedIntersectObserver) {
      unRegisterObserver()
    }
  },
)
</script>

<template>
  <div>
    <div class="w-full flex items-center justify-between">
      <div class="flex items-center relative">
        <BomStatusFilter :options="Status" :value="state.status" @update:model-value="filterStatusChanged" />
        <div class="pl-2">
          <OSearchBar
            v-model="state.search"
            :placeholder="`${$t('global.search')}`"
            class="!w-96"
            @update:modelValue="searchTextChanged"
          />
        </div>
      </div>
      <div class="flex">
        <button
          class="btn-danger flex items-center gap-2 first:mr-2"
          v-if="state.selectedBomsForDelete.length > 0"
          @click="openDeleteBomModal"
        >
          <div i="carbon-trash-can" />
          {{ $t('global.delete') }}
        </button>

        <div v-if="state.boms.length > 0 && canCreateBom" class="mr-2">
          <button
            class="btn-secondary gap-1.5 h-full"
            data-test-id="select-unselect-entities"
            @click="selectOrUnselectAllBoms"
          >
            <div v-if="state.selectedBomsForDelete.length <= 0" class="flex items-center">
              <div i="carbon-checkbox" />
              <div class="pl-2">{{ $t('global.select') }}</div>
            </div>
            <div v-else class="flex items-center">
              <div i="carbon-checkbox-indeterminate" />
              <div class="pl-2">{{ $t('global.unselect') }}</div>
            </div>
          </button>
        </div>
        <div class="flex" v-if="canCreateBom">
          <button class="btn-secondary gap-1.5 h-full h-fit mr-2" @click="openImportModal">
            <div i="carbon-upload" />
            {{ $t('global.import') }}
          </button>
          <button class="btn-primary h-full" @click="openAddBomModal">
            <div i="carbon-intent-request-create" />
            {{ $t('global.new') }}
          </button>
        </div>
      </div>
    </div>
    <div class="mt-8">
      <!-- Table -->
      <div class="grid grid-cols-11 px-2.5 gap-1 text-[#475467] text-center text-sm">
        <div class="flex col-span-1 justify-center">
          <button
            class="text-center opacity-30"
            @click="selectOrUnselectAllBoms"
            v-if="state.boms.length > 0 && canCreateBom"
          >
            <div v-if="state.selectedBomsForDelete.length > 0" i="carbon-checkbox-indeterminate" />
            <div v-else i="carbon-checkbox" />
          </button>
        </div>
        <div scope="col" class="text-left col-span-2">{{ t('global.name') }}</div>
        <div scope="col" class="text-left">{{ t('global.image') }}</div>
        <div scope="col" class="text-left">{{ t('global.status') }}</div>
        <div scope="col" class="text-left col-span-3">{{ t('global.description') }}</div>
        <div scope="col" class="text-left col-span-1">{{ t('global.reference') }}</div>
        <!-- <div scope="col" class="text-left col-span-2">{{ t('global.participants') }}</div> -->
        <div scope="col" class="text-left">{{ t('global.last_updated') }}</div>
        <div scope="col">{{ t('global.action', 2) }}</div>
      </div>
      <div class="flex flex-col mt-4">
        <template v-for="(bom, index) in state.boms" :key="bom.uuid">
          <button
            :data-testid="`bom-${index}`"
            :class="{ 'cursor-not-allowed': !bom.auth.can.view }"
            class="group grid grid-cols-11 items-center gap-1 text-[#344054] text-center text-sm bg-white hover:bg-gray-50 border border-[#F2F4F7] not-first:border-t-0 first:rounded-t last:rounded-b px-2 py-1"
            @click="goToBomDetails(bom)"
          >
            <div class="col-span-1 flex justify-center">
              <button @click.stop="appendOrRemoveForDelete(bom)" v-if="bom.auth.can.delete">
                <div i="carbon-checkbox-checked-filled" class="text-[#3B82F6]" v-if="bom.is_checked" />
                <div i="carbon-checkbox" class="opacity-40" v-else></div>
              </button>
            </div>

            <div class="col-span-2 flex items-center gap-2">
              <!-- <EntityIcon :entity="bom" class="group-hover:bg-[#DEE9FF]" :tooltip="true" /> -->
              <div class="text-left text-[#1D2939] text-sm break-after-all truncate" :title="bom.name">
                {{ bom.name }}
              </div>
            </div>

            <div
              class="col-span-1 flex items-center min-h-10 max-h-10 overflow-hidden rounded"
              @click="openFullScreenImageModal(bom?.image)"
            >
              <img :src="bom.image" v-if="bom?.image" class="object-contain w-[42px] h-auto aspect-square" />
            </div>

            <div class="col-span-1 flex items-center gap-2">
              <PublishingStatusTag :status="bom.status" />
            </div>

            <div class="col-span-3 flex items-center gap-2">
              <div
                class="text-left text-sm text-[#475467] line-clamp-2 truncate max-w-90 text-overflow-ellipsis inline-block"
                :title="bom.description"
              >
                {{ bom.description }}
              </div>
            </div>

            <div class="col-span-1 flex items-center gap-2">
              <div
                class="text-left text-sm text-[#475467] line-clamp-2 truncate max-w-90 text-overflow-ellipsis inline-block"
                :title="bom.description"
              >
                {{ bom.reference }}
              </div>
            </div>

            <!-- <div class="flex items-center justify-left gap-2 col-span-2">
              <OAvatars :users="bom.participants" size="1.5" />
            </div> -->

            <!-- <div class="flex items-center gap-2 col-span-3">
              <div class="text-left text-sm text-[#475467] line-clamp-2 truncate max-w-90 text-overflow-ellipsis inline-block" :title="bom.description">
                {{ bom.variabilities }}
              </div>
            </div> -->

            <!-- saw this one on sentry -->
            <div class="text-sm whitespace-nowrap col-span-1 text-left">
              <template v-if="bom.updated_at">
                {{
                  formatDistance(new Date(bom.updated_at), new Date(), {
                    addSuffix: true,
                    locale: getDateFnsLocaleFromBrowserLanguage()!,
                  })
                }}
              </template>
            </div>
            <button
              class="p-1 mx-auto border border-transparent text-sm text-[#475467] transition hover:border-[#E6E6E5] col-span-1"
              @click.stop.prevent="goToBomDetails(bom)"
            >
              <div i="carbon-arrow-up-right" />
            </button>
          </button>
        </template>
      </div>
      <div id="issueObserver" />
      <div v-if="state.isLoading" class="my-10">
        <Loader />
      </div>
      <div v-if="!state.isLoading && state.boms.length <= 0">
        <div class="text-center mt-8 text-black">
          {{ t('boms.no_found') }}
        </div>
      </div>
    </div>

    <OModal :open="state.hasDeleteBomModalOpen">
      <template #content>
        <div class="flex flex-col gap-1">
          <h2 class="text-xl font-semibold">
            {{ $t('global.confirm_delete') }}
          </h2>
          <p>
            {{ $t('boms.delete_title') }}
          </p>
          <span
            class="mt-4 rounded-md bg-red-50 px-2 py-1 text-s font-medium text-red-700 ring-1 ring-inset ring-red-600/10"
          >
            <b>Warning:&nbsp;</b>{{ $t('boms.delete_description') }}
          </span>
        </div>
      </template>

      <template #footer>
        <div class="flex justify-end w-full gap-4">
          <button class="btn-secondary" :disabled="state.isDeleting" @click="closeDeleteBomModal">
            {{ t('global.cancel') }}
          </button>
          <button class="btn-danger bg-red-500 text-white" autofocus :disabled="state.isDeleting" @click="deleteBoms">
            {{ state.isDeleting ? t('global.deleting') : t('global.delete') }}
          </button>
        </div>
      </template>
    </OModal>

    <EntityCreationModal
      :open="state.hasAddBomModalOpen"
      default-type="Bom"
      :isBomPage="true"
      @close="closeAddBomModal"
      @create="createBom"
    />

    <GraphElementImportModal
      :open="state.isImportModalOpen"
      @close="closeImportModal"
      @import-success="loadPaginatedData"
      import-from="bom"
    />

    <!-- Image modal -->
    <OModal :open="state.isFullscreenImageModalOpen" @close="closeFullScreenImageModal">
      <template #content>
        <img :src="state.imagePath" />
      </template>

      <template #footer>
        <div class="flex justify-end w-full gap-4">
          <button class="btn-primary w-full" @click="closeFullScreenImageModal">
            {{ $t('global.close') }}
          </button>
        </div>
      </template>
    </OModal>
  </div>
</template>
