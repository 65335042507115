import * as _ from 'lodash'
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore'
import { LoggerHelper } from '../LoggerHelper'
import { db, fb_auth } from '~/services/firebase'

const logger = new LoggerHelper()
/* DbHelper v9 */
export default class DBHelper {
  db: any
  constructor() {
    this.db = db
  }

  addTimeStampAndUserData(data: any, creation = false) {
    const newData = { ...data }
    if (creation) {
      newData._created_at = new Date().toISOString()
      newData._created_by = fb_auth.currentUser?.uid
    }
    newData.updated_at = new Date().toISOString()
    newData.updated_by = fb_auth.currentUser?.uid
    return newData
  }

  async batchAdd(collectionName: string, data: any) {
    const batchArray = []
    batchArray.push(writeBatch(db))
    let operationCounter = 0
    let batchIndex = 0

    for (const document of data) {
      const docRef = doc(collection(db, collectionName)) //  doc(db, collection)
      const docData = this.addTimeStampAndUserData(document, true)
      batchArray[batchIndex].set(docRef, docData)
      operationCounter++
      if (operationCounter === 499) {
        batchArray.push(writeBatch(db))
        batchIndex++
        operationCounter = 0
      }
    }

    batchArray.forEach(async (batch: any) => await batch.commit())
    return batchArray
  }

  async batchUpdate(collection: string, data: any) {
    const batchArray = []
    batchArray.push(writeBatch(db))
    let operationCounter = 0
    let batchIndex = 0
    for (const document of data) {
      const docRef = doc(db, collection, document.id)
      const docBactch = this.addTimeStampAndUserData({ ...document })
      delete docBactch.id
      batchArray[batchIndex].update(docRef, docBactch)
      operationCounter++
      if (operationCounter === 499) {
        batchArray.push(writeBatch(db))
        batchIndex++
        operationCounter = 0
      }
    }
    batchArray.forEach(async (batch: any) => await batch.commit())
    return batchArray
  }

  async batchDelete(collection: string, data: any) {
    const batchArray = []
    batchArray.push(writeBatch(db))
    let operationCounter = 0
    let batchIndex = 0
    for (const document of data) {
      const docRef = doc(db, collection, document.id)
      batchArray[batchIndex].delete(docRef)
      operationCounter++
      if (operationCounter === 499) {
        batchArray.push(this.db.batch())
        batchIndex++
        operationCounter = 0
      }
    }

    batchArray.forEach(async (batch: any) => await batch.commit())
    return batchArray
  }

  async setDataToCollection(collectionName: string, id: string, data: any) {
    const docRef = collection(db, collectionName)
    const docData = this.addTimeStampAndUserData(data, true)
    return await setDoc(doc(docRef, id), docData)
  }

  async addDataToCollection(collectionName: string, data: any) {
    const docData = this.addTimeStampAndUserData(data, true)
    return await addDoc(collection(db, collectionName), docData)
  }

  async updateDataToCollection(collectionName: string, id: string, data: any) {
    const docRef = doc(db, collectionName, id)
    const docData = this.addTimeStampAndUserData(data, true)
    return await updateDoc(docRef, docData)
  }

  async deleteData(collectionName: string, id: string) {
    return await deleteDoc(doc(db, collectionName, id))
  }

  async getAllDataFromCollectionBetweenDates(collectionName: string, whatIs: string, startDate: Date, endDate: Date) {
    const returnArray: Array<any> = []
    const q = query(collection(db, collectionName), where(whatIs, '>', startDate), where(whatIs, '<', endDate))
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((docSnap: any) => {
      const resultQuery = docSnap.data()
      resultQuery.id = docSnap.id
      returnArray.push(resultQuery)
    })
    return returnArray
  }

  async getAllDataFromCollectionFromIds(collectionName: string, equalTo: Array<any>) {
    const returnArray: Array<any> = []
    if (equalTo?.length) {
      const equalToUniq = _.uniq(equalTo)
      const equalToChunked = _.chunk(equalToUniq, 10)
      for (const tenBloc of equalToChunked) {
        const q = query(collection(db, collectionName), where('__name__', 'in', tenBloc))
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach((docSnap: any) => {
          const resultQuery = docSnap.data()
          resultQuery.id = docSnap.id
          returnArray.push(resultQuery)
        })
      }
    }
    return returnArray
  }

  async getAllDataFromCollectionWithWhere(collectionName: string, whatIs: string, equalTo: any) {
    const returnArray: Array<any> = []
    const q = query(collection(db, collectionName), where(whatIs, '==', equalTo))
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((docSnap: any) => {
      const resultQuery = docSnap.data()
      resultQuery.id = docSnap.id
      returnArray.push(resultQuery)
    })
    return returnArray
  }

  async getAllDataFromCollectionWithWhereInArray(collectionName: string, whatIs: string, equalTo: any) {
    const returnArray: Array<any> = []
    const q = query(collection(db, collectionName), where(whatIs, 'array-contains', equalTo))
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((docSnap: any) => {
      const resultQuery = docSnap.data()
      resultQuery.id = docSnap.id
      returnArray.push(resultQuery)
    })
    return returnArray
  }

  async getAllDataFromCollection(collectionName: string) {
    const returnArray: Array<any> = []
    const q = query(collection(db, collectionName))
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((docSnap: any) => {
      const resultQuery = docSnap.data()
      resultQuery.id = docSnap.id
      returnArray.push(resultQuery)
    })
    return returnArray
  }

  async getDocFromCollection(collectionName: string, docId: string) {
    let result = null
    if (docId) {
      const docRef9 = doc(this.db, collectionName, docId)
      const docSnap = await getDoc(docRef9)

      if (docSnap.exists()) {
        const resultQuery: any = docSnap.data()
        resultQuery.id = docSnap.id
        result = resultQuery
      } else {
        // doc.data() will be undefined in this case
        const error = `getDocFromCollection - ${collection} - ${docId}`
        console.error('getDocFromCollection,', collectionName, docId)
        logger.logError('dbGetDoc', error)
        throw new Error(`Document ${docId} not found on collection ${collectionName}`)
      }
    }
    return result
  }

  async getDocFromCollectionOnSnapshot(collectionName: string, docId: string, callBack: any) {
    if (docId) {
      const snap = onSnapshot(doc(db, collectionName, docId), (doc: any) => {
        const resultQuery: any = doc.data()
        resultQuery.id = doc.id
        callBack(resultQuery)
        return resultQuery
      })
      return snap
    }
  }

  async getAllDataFromCollectionWithWhereArrayOnSnapshot(
    collectionName: string,
    arrayWhere: Array<any>,
    callBack: any,
  ) {
    const returnArray: Array<any> = []
    const whereArgsArray = []
    const keys = Object.keys(arrayWhere)
    for (let i = 0; i < keys.length; i += 1) {
      const prop: any = keys[i]
      whereArgsArray.push(where(prop, '==', arrayWhere[prop]))
    }
    const q = query(collection(db, collectionName), ...whereArgsArray)
    const unsubscribe = onSnapshot(q, querySnapshot => {
      querySnapshot.forEach(docSnap => {
        const resultQuery = docSnap.data()
        resultQuery.id = docSnap.id
        returnArray.push(resultQuery)
      })
    })
    callBack(returnArray)
    return returnArray
  }

  async getAllDataFromCollectionWithWhereIn(
    collectionName: string,
    whatIs: string,
    equalTo: Array<any>,
    arrayWhere: Array<any> = [],
  ) {
    const returnArray: Array<any> = []
    const whereArgsArray = []
    const keys = Object.keys(arrayWhere)
    for (let i = 0; i < keys.length; i += 1) {
      const prop: any = keys[i]
      whereArgsArray.push(where(prop, '==', arrayWhere[prop]))
    }
    const equalToChunked = _.chunk(equalTo, 10)

    for (let i = 0; i < equalToChunked.length; i++) {
      const cloneWhereArgsArray = [...whereArgsArray]
      cloneWhereArgsArray.push(where(whatIs, 'in', equalToChunked[i]))
      const q = query(collection(db, collectionName), ...cloneWhereArgsArray)
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((docSnap: any) => {
        const resultQuery = docSnap.data()
        resultQuery.id = docSnap.id
        returnArray.push(resultQuery)
      })
    }
    return returnArray
  }

  async getAllDataFromCollectionWithAll(collectionName: string, constraints: any) {
    const returnArray: Array<any> = []
    const constraintsArgsArray = []

    const { where_constraints, orderBy_constraints, limit_constraint } = constraints || {}
    if (where_constraints) {
      where_constraints.forEach((cond: any) => {
        constraintsArgsArray.push(where(cond.field, cond.compare, cond.value))
      })
    }
    if (orderBy_constraints) {
      orderBy_constraints.forEach((cond: any) => {
        constraintsArgsArray.push(orderBy(cond.field, cond.direction || 'asc'))
      })
    }
    if (limit_constraint) constraintsArgsArray.push(limit(limit_constraint))

    const q = query(collection(db, collectionName), ...constraintsArgsArray)
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((docSnap: any) => {
      const resultQuery = docSnap.data()
      resultQuery.id = docSnap.id
      returnArray.push(resultQuery)
    })
    return returnArray
  }

  async countAllDataFromCollectionWithAll(collectionName: string, constraints: any) {
    const constraintsArgsArray = []

    const { where_constraints, orderBy_constraints, limit_constraint } = constraints || {}
    if (where_constraints) {
      where_constraints.forEach((cond: any) => {
        constraintsArgsArray.push(where(cond.field, cond.compare, cond.value))
      })
    }
    if (orderBy_constraints) {
      orderBy_constraints.forEach((cond: any) => {
        constraintsArgsArray.push(orderBy(cond.field, cond.direction || 'asc'))
      })
    }
    if (limit_constraint) constraintsArgsArray.push(limit(limit_constraint))

    const q = query(collection(db, collectionName), ...constraintsArgsArray)
    const querySnapshot = await getCountFromServer(q)
    return querySnapshot?.data()?.count
  }

  async getAllDataFromCollectionWithWhereArray(collectionName: string, arrayWhere: Record<string, any>) {
    const returnArray: Array<any> = []
    const whereArgsArray = []
    const keys = Object.keys(arrayWhere)
    for (let i = 0; i < keys.length; i += 1) {
      const prop: any = keys[i]
      whereArgsArray.push(where(prop, '==', arrayWhere[prop]))
    }
    const q = query(collection(db, collectionName), ...whereArgsArray)
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((docSnap: any) => {
      const resultQuery = docSnap.data()
      resultQuery.id = docSnap.id
      returnArray.push(resultQuery)
    })
    return returnArray
  }

  async countAllDataFromCollectionWithWhereArray(collectionName: string, arrayWhere: Record<string, any>) {
    const whereArgsArray = []
    const keys = Object.keys(arrayWhere)
    for (let i = 0; i < keys.length; i += 1) {
      const prop: any = keys[i]
      whereArgsArray.push(where(prop, '==', arrayWhere[prop]))
    }
    const q = query(collection(db, collectionName), ...whereArgsArray)
    const querySnapshot = await getCountFromServer(q)
    return querySnapshot?.data()?.count
  }

  async getDocFromCollectionWithWhere(collectionName: string, arrayWhere: Array<any>) {
    let result = null
    const whereArgsArray = []
    const keys = Object.keys(arrayWhere)
    for (let i = 0; i < keys.length; i += 1) {
      const prop: any = keys[i]
      whereArgsArray.push(where(prop, '==', arrayWhere[prop]))
    }

    const q = query(collection(db, collectionName), ...whereArgsArray)
    const querySnapshot: any = await getDocs(q)
    // eslint-disable-next-line no-unreachable-loop
    for (const docSnap of querySnapshot) {
      const resultQuery = docSnap.data()
      resultQuery.id = docSnap.id
      result = resultQuery
      break
    }
    return result
  }
}
