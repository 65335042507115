<script setup lang="ts">
import { apiStore } from '~/stores/api'

const api = apiStore().getApiClient
const router = useRouter()

const data = reactive({
  widget: {
    openFeedbacks: 0,
    criticalFeedbacks: 0,
    highFeedbacks: 0,
    mediumFeedbacks: 0,
    lowFeedbacks: 0,
    noneFeedbacks: 0,
  },
})

const state = reactive({
  isLoading: true,
})

function loadData() {
  api
    .getTodosCount()
    .then(res => {
      data.widget.openFeedbacks = res.unresolved.total
      data.widget.criticalFeedbacks = res.unresolved.critical
      data.widget.highFeedbacks = res.unresolved.high
      data.widget.mediumFeedbacks = res.unresolved.medium
      data.widget.lowFeedbacks = res.unresolved.low
      data.widget.noneFeedbacks = res.unresolved.none
    })
    .finally(() => {
      state.isLoading = false
    })
}

onMounted(() => {
  loadData()
})
</script>

<template>
  <!-- this is hidden as we're using it in the index which already includes the header -->
  <!-- <div class="flex flex-col h-full bg-[#FCFCFD]">
    <div class="flex w-full pl-4 ">
      <div class="flex flex-col container gap-1 pt-4 pb-2">
        <div class="flex gap-2 text-sm ml-10">
          <router-link
            to="/"
            class="text-[#98A2B3]"
          >
            {{ $t("global.dashboard") }}
          </router-link>
        </div>
        <div class="flex justify-between">
          <div class="flex items-center gap-4 text-2xl text-[#181A4E]">
            <button class="text-lg bg-white rounded transition hover:bg-[#F4F4F5] hover:text-[#3B82F6]" @click="router.go(-1)">
              <div i="carbon-chevron-left" />
            </button>
            {{ $t("global.dashboard") }}
          </div>
        </div>
      </div>
    </div> -->
  <div v-if="!state.isLoading" class="p-4 md:p-6 pt-2 overflow-y-scroll flex flex-col gap-4 lg:mx-12">
    <div class="flex flex-col gap-2 mx-4">
      <!-------------------------WIDGET--------------------------------------------->
      <div class="grid lg:grid-cols-2 grid-cols-1 gap-4 lg:w-auto w-full">
        <Widget
          :title="$t('global.total_assigned_unresolved_feedbacks')"
          :data="data.widget.openFeedbacks"
          icon="carbon-book"
          icon-color="text-[#9E77ED]"
          icon-background="bg-[#F4EBFF]"
          data-testid="total-assigned-unresolved"
        />
        <Widget
          :title="$t('global.total_assigned_unresolved_critical_feedbacks')"
          :data="data.widget.criticalFeedbacks"
          icon="carbon-lightning"
          icon-color="text-[#B42318]"
          icon-background="bg-[#FDE9E7]"
          data-testid="total-critical-unresolved"
        />
        <Widget
          :title="$t('global.total_assigned_unresolved_medium_feedbacks')"
          :data="data.widget.mediumFeedbacks"
          icon="carbon-warning-alt"
          icon-color="text-[#92400E]"
          icon-background="bg-[#FFF7D6]"
          data-testid="total-medium-priority-unresolved"
        />
        <Widget
          :title="$t('global.total_assigned_unresolved_low_feedbacks')"
          :data="data.widget.lowFeedbacks"
          icon="carbon-information"
          icon-color="text-[#2E32D4]"
          icon-background="bg-[#EDF3FF]"
          data-testid="total-low-priority-unresolved"
        />
        <Widget
          :title="$t('global.total_assigned_unresolved_none_feedbacks')"
          :data="data.widget.noneFeedbacks"
          icon="carbon-information"
          icon-color="text-[#2E32D4]"
          icon-background="bg-[#EDF3FF]"
          data-testid="total-no-priority-unresolved"
        />
      </div>
    </div>
  </div>
  <Loader v-else />
  <!-- </div> -->
</template>

<route lang="yaml">
meta:
  layout: home
  requiresAuth: true
</route>
