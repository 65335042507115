<script setup lang="ts">
import { userStore } from '~/stores/user'
import { UserRoles } from '~/types/user'
import Dashboard from './dashboard.vue'
const router = useRouter()
const { t } = useI18n()

const pageTitle = computed(() => {
  if (userStore().userHasRole(UserRoles.PROJECT_MANAGER, UserRoles.ADMIN)) {
    return t('global.boms', 2)
  } else if (userStore().userHasRole(UserRoles.CATALOG_MANAGER)) {
    return t('global.catalog', 2)
  } else if (userStore().userHasRole(UserRoles.SOURCING_MANAGER)) {
    return t('global.sourcing', 2)
  } else {
    return t('global.dashboard')
  }
})

const pageSubtitle = computed(() => {
  if (userStore().userHasRole(UserRoles.PROJECT_MANAGER, UserRoles.ADMIN)) {
    return t('global.boms', 2)
  } else if (userStore().userHasRole(UserRoles.CATALOG_MANAGER)) {
    return t('global.catalog', 2)
  } else if (userStore().userHasRole(UserRoles.SOURCING_MANAGER)) {
    return t('global.sourcing', 2)
  } else {
    return t('global.dashboard')
  }
})
</script>

<template>
  <div class="flex flex-1 flex-col h-full max-h-full overflow-auto pb-4 bg-[#FCFCFD]">
    <div class="flex flex-1 flex-col gap-2 py-4">
      <div class="flex flex-col gap-1 pl-4 text-sm">
        <p class="text-[#9A9A98] ml-10">
          {{ pageSubtitle }}
        </p>
        <div class="flex items-center gap-4 text-2xl text-[#262626]">
          <button
            class="text-lg bg-white rounded transition hover:bg-[#F4F4F5] hover:text-[#3B82F6]"
            @click="router.go(0)"
          >
            <div i="carbon-chevron-left" />
          </button>
          {{ pageTitle }}
        </div>
      </div>
      <div class="px-8 py-4">
        <template v-if="userStore().userHasRole(UserRoles.PROJECT_MANAGER, UserRoles.ADMIN)">
          <BomsList />
        </template>
        <template v-else-if="userStore().userHasRole(UserRoles.CATALOG_MANAGER)">
          <LibraryEntitiesList />
        </template>
        <template v-else-if="userStore().userHasRole(UserRoles.SOURCING_MANAGER)">
          <SuppliersList />
        </template>
        <template v-else>
          <Dashboard />
        </template>
      </div>
    </div>
  </div>
</template>

<route lang="yaml">
meta:
  layout: home
  requiresAuth: true
</route>
