<script setup lang="ts">
import tippy from 'tippy.js'
import { v4 as uuidv4 } from 'uuid'
import { entityTypesStore } from '~/stores/entity_types'
import { Entity } from '~/types/view-elements'
import { getBackgroundColorForEntityTypeColor } from '~/utils/entityTypeColors'

/**
 * @entity : Entity
 * (The entity to display)
 *
 * @size : Number | String
 * (The size * 2 of the icon // size * 4 of the container in px)
 *
 * @class : String
 * (The class to add to the icon container)
 *
 * @tooltip : Boolean
 * (Whether to display a tooltip on hover)
 */
const props = defineProps({
  entity: {
    type: Object as PropType<Pick<Entity, 'system_attributes' | 'schema_type' | 'group' | 'name' | 'description'>>,
    required: true,
  },
  size: {
    types: [Number, String],
    required: false,
    default: 10,
  },
  class: {
    type: String,
    required: false,
    default: '',
  },
  tooltip: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const state = reactive({
  randUuid: uuidv4(),
})

const icon = computed(() => {
  return (entity: Pick<Entity, 'system_attributes'>) => {
    return entity?.system_attributes?.styling?.icon && entity?.system_attributes?.styling?.icon.startsWith('carbon-')
      ? entity?.system_attributes?.styling?.icon
      : 'carbon-cube'
  }
})

const iconColor = computed(() => {
  return (entity: Pick<Entity, 'system_attributes'>) => {
    return entity?.system_attributes?.styling?.color || '#181A4E'
  }
})

// Computed property to get display_name or fallback to name
const displayName = computed(() => {
  return (entity: Pick<Entity, 'system_attributes' | 'schema_type' | 'group' | 'name' | 'description'>) => {
    const matchedEntity = entityTypesStore().getEntityTypesAsOptionsWithNameAsValue.find(
      e => e.name === entity.schema_type || e.name === entity.group,
    )
    return (
      matchedEntity?.display_name ||
      entity.schema_type ||
      entity.group ||
      entity.name ||
      entity.description ||
      'Unknown'
    )
  }
})

function setupTippy() {
  // TODO : on hover for performances
  if (props.tooltip) {
    // content should never land on name or label, but on schema_type or group
    // Displaying them to avoid empty tooltips in some cases (temporary)
    tippy(`#entity-icon-${state.randUuid}`, {
      content: displayName.value(props.entity),
      placement: 'top',
    })
  }
}
</script>

<template>
  <div
    :id="`entity-icon-${state.randUuid}`"
    :class="`flex items-center justify-center flex-shrink-0 rounded ${props.class}`"
    :style="{
      width: size * 4 + 'px',
      height: size * 4 + 'px',
      backgroundColor: getBackgroundColorForEntityTypeColor(entity?.system_attributes?.styling?.color || '#181A4E'),
    }"
    @mouseover="setupTippy"
  >
    <div
      :i="icon(props.entity)"
      :style="{ width: size * 2 + 'px', height: size * 2 + 'px', color: iconColor(props.entity) }"
    />
  </div>
</template>
