// Supported ET colors
export function entityTypeColors() {
  return [
    {
      text: 'Black',
      color: '#181A4E',
    },
    {
      text: 'Blue',
      color: '#373FEF',
    },
    {
      text: 'Brown',
      color: '#BC6C25',
    },
    {
      text: 'Green',
      color: '#1B9488',
    },
    {
      text: 'Grey',
      color: '#667085',
    },
    {
      text: 'Orange',
      color: '#EA580B',
    },
    {
      text: 'Pink',
      color: '#DB2777',
    },
    {
      text: 'Purple',
      color: '#9E77ED',
    },
    {
      text: 'Red',
      color: '#ED2A1C',
    },
    {
      text: 'Yellow',
      color: '#EAB307',
    },
  ]
}

export function getBackgroundColorForEntityTypeColor(color: string) {
  switch (color) {
    case '#000000':
    case '#181A4E':
      return '#F2F4F7'
    case '#373FEF':
      return '#DEE9FF'
    case '#BC6C25':
      return '#FFF7D9'
    case '#1B9488':
      return '#E5FBF6'
    case '#667085':
      return '#FCFCFD'
    case '#EA580B':
      return '#FFEDD5'
    case '#DB2777':
      return '#FDF2F8'
    case '#9E77ED':
      return '#F4EBFF'
    case '#ED2A1C':
      return '#FDE9E7'
    case '#EAB307':
      return '#FEFCE8'
    default:
      return '#F8FAFC'
  }
}
