import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { Auth } from 'firebase/auth'
import qs from 'qs'
import { getBackEndPhpURL } from '~/config/index'
import { fb_auth } from '~/services/firebase'
import {
  deleteRequest,
  Filters,
  getPaginatedRequest,
  getRawRequest,
  getRequest,
  patchRequest,
  postRawRequest,
  postRequest,
  putRequest,
} from '~/types/api'
import { BroadcastAuthRequest, BroadcastAuthResponse } from '~/types/auth'
import {
  ActivityLog,
  Feedback,
  FeedbackComment,
  NewFeedback,
  NewFeedbackComment,
  UpdateFeedback,
} from '~/types/collaboration_feedbacks'
import { Diversity, NewDiversity } from '~/types/diversity'
import { ClientSettings } from '~/types/configs'
import type { Relationship } from '~/types/relationship'
import {
  Attribute,
  Bom,
  CreateAttribute,
  CreateEntitySchema,
  CreateView,
  EntitySchema,
  GraphData,
  PreviewSyncBomResponse,
  SyncBomResponse,
  UpdateAttribute,
  UpdateEntitySchema,
  UpdateView,
  View,
  type Instance,
  type SyncBomRequest,
} from '~/types/view-elements'

export const apiConfigPhp = {
  returnRejectedPromiseOnError: true,
  timeout: 60000,
  baseURL: getBackEndPhpURL(),
  paramsSerializer: {
    encode: (params: Record<string, unknown>) => qs.stringify(params, { indices: false }),
  },
}

export default class ApiHelper {
  apiClientPhp: AxiosInstance
  userTokenId: string
  fb_auth: Auth

  constructor() {
    this.apiClientPhp = axios.create(apiConfigPhp)
    this.fb_auth = fb_auth
    this.userTokenId = ''
  }

  setUserTokenId(userTokenId: string) {
    this.userTokenId = userTokenId
  }

  async getToken() {
    const user = await this.fb_auth?.currentUser
    const token = await user?.getIdToken()

    if (token) this.setUserTokenId(token)
  }

  async getHeaders(content_type = 'application/json'): Promise<AxiosRequestConfig> {
    await this.getToken()
    const h = {
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        'Content-Type': content_type,
        Accept: 'application/json',
        Authorization: `Bearer ${this.userTokenId}`,
        'Accept-Language': navigator.language.includes('fr') ? 'fr' : 'en',
      },
    }
    return h
  }

  // Used to get Pusher Auth (WebSockets)
  async getBroadcastingAuth(payload: { socket_id: string; channel_name: string }) {
    const request = postRawRequest<BroadcastAuthResponse, BroadcastAuthRequest>(
      this.apiClientPhp,
      await this.getHeaders(),
    )
    const { data } = await request('broadcasting/auth', payload)
    return data
  }

  // Start of PHP API
  async importETL(data: unknown) {
    const resultAxios = await this.apiClientPhp.post('/api/v1/etl/import', data, await this.getHeaders())
    return resultAxios?.data
  }

  async getUsersPhp() {
    const resultAxios = await this.apiClientPhp.get('/api/v1/users?per_page=500', await this.getHeaders())
    return resultAxios?.data
  }

  async searchEntity(
    search: string,
    filter = {} as { schema_type?: string; entity_uuid?: string; relationship_type?: string },
  ) {
    search = encodeURIComponent(search?.trim())
    const filterSchemaType = filter?.schema_type ? `&schema_type=${filter?.schema_type}` : ''
    const entity_uuid = filter?.entity_uuid ? `&entity_uuid=${filter?.entity_uuid}` : '' // Entity_uuid refers here to the 'from' entity, not the entity to be searched
    const relationship_type = filter?.relationship_type ? `&relationship_type=${filter?.relationship_type}` : ''
    const filters = filterSchemaType + entity_uuid + relationship_type
    const resultAxios = await this.apiClientPhp.get(
      `/api/v1/entities/search?page=1&s=${search}&per_page=100${filters}`,
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async searchEntityByName(page: number, perPage: number, search: string) {
    search = encodeURIComponent(search?.trim())
    const resultAxios = await this.apiClientPhp.get(
      `/api/v1/entities/search-by-name?filter[search]=${search}&per_page=${perPage}&page=${page}`,
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async searchDiversity(page: number, perPage: number, search: string) {
    search = encodeURIComponent(search?.trim())
    const resultAxios = await this.apiClientPhp.get(
      `/api/v1/diversities?filter[search]=${search}&per_page=${perPage}&page=${page}`,
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  // Create Entity
  async createEntity(data: unknown) {
    const resultAxios = await this.apiClientPhp.post('/api/v1/entities', data, await this.getHeaders())
    return resultAxios?.data
  }

  async getEntityPhp(uuid: string, projectUuid: string = ''): Promise<{ data: unknown }> {
    const projectId = projectUuid ? `project_id=${projectUuid}` : ''
    const query = `/api/v1/entities/${uuid}?${projectId}`
    const resultAxios = await this.apiClientPhp.get(query, await this.getHeaders())
    return resultAxios?.data
  }

  async getBomDiversities(uuid: string, page: number = 1, per_page: number = 15) {
    const request = getPaginatedRequest<Diversity[]>(this.apiClientPhp, await this.getHeaders())
    const { data } = await request(
      `/api/v1/boms/${uuid}/diversities?page=${page}&per_page=${per_page}`,
    )
    return data
  }

  async getDiversity(uuid: string): Promise<{ data: any }> {
    const request =  getRequest<Diversity>(this.apiClientPhp, await this.getHeaders())
    const { data } = await request(`/api/v1/diversities/${uuid}`)
    return data
  }

  async getDiversities(): Promise<{ data: any }> {
    const request = getRequest<Diversity[]>(this.apiClientPhp, await this.getHeaders())
    const { data } = await request(`/api/v1/diversities`)
    return data
  }

  async createDiversity(payload: NewDiversity) {
    const request = postRequest<Diversity, NewDiversity>(this.apiClientPhp, await this.getHeaders())
    const { data } = await request('/api/v1/diversities', payload)
    return data
  }

  async updateDiversity(id: string, payload: NewDiversity) {
    const request = putRequest<Diversity, NewDiversity>(this.apiClientPhp, await this.getHeaders())
    const { data } = await request(`/api/v1/diversities/${id}`, payload)
    return data
  }

  async updateBomVariabilities(id: string, payload: object) {
    const query = `/api/v1/boms/${id}/`
    const resultAxios = await this.apiClientPhp.patch(query, payload, await this.getHeaders())
    return resultAxios?.data
  }

  async getBom(bom_id: string) {
    const request = getRequest<Bom>(this.apiClientPhp, await this.getHeaders())
    const query = `/api/v1/boms/${bom_id}`
    const { data } = await request(query)
    return data
  }

  async getInstance(uuid: string): Promise<{ data: Instance }> {
    const resultAxios = await this.apiClientPhp.get(`/api/v1/bom-instances/${uuid}`, await this.getHeaders())
    return resultAxios?.data
  }

  async createInstance(payload: object): Promise<{ data: Instance }> {
    const resultAxios = await this.apiClientPhp.post('/api/v1/bom-instances', payload, await this.getHeaders())
    return resultAxios?.data
  }

  async updateInstance(uuid: string, payload: object): Promise<{ data: Instance }> {
    const resultAxios = await this.apiClientPhp.put(`/api/v1/bom-instances/${uuid}`, payload, await this.getHeaders())
    return resultAxios?.data
  }

  async deleteInstance(uuid: string): Promise<{ data: Instance }> {
    const resultAxios = await this.apiClientPhp.delete(`/api/v1/bom-instances/${uuid}`, await this.getHeaders())
    return resultAxios?.data
  }

  async updateInstanceVariabilities(uuid: string, payload: object): Promise<{ data: Instance }> {
    const resultAxios = await this.apiClientPhp.put(
      `/api/v1/bom-instances/${uuid}/variabilities`,
      payload,
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async getRelationship(payload: {
    relationship_name: string
    from_uuid: string
    to_uuid: string
    project_uuid: string
    variability_id: string
  }): Promise<Relationship> {
    const query = `/api/v1/entities/show-edge?relationship_name=${payload.relationship_name}&from_uuid=${payload.from_uuid}&to_uuid=${payload.to_uuid}`
    const projectIdQueryParam = payload.project_uuid ? `&project_id=${payload.project_uuid}` : ''
    const variabilityQueryParam = payload.variability_id ? `&variability_id=${payload.variability_id}` : ''
    const resultAxios = await this.apiClientPhp.get(
      `${query}${variabilityQueryParam}${projectIdQueryParam}`,
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async getRevisions(id: string) {
    const query = `/api/v1/entities/${id}/revisions`
    const resultAxios = await this.apiClientPhp.get(query, await this.getHeaders())
    return resultAxios?.data
  }

  async getBomRevisions(bom_id: string) {
    const query = `/api/v1/boms/${bom_id}/revisions`
    const resultAxios = await this.apiClientPhp.get(query, await this.getHeaders())
    return resultAxios?.data
  }

  async createBomRevision(bom_id: string) {
    const query = `/api/v1/boms/${bom_id}/revisions`
    const resultAxios = await this.apiClientPhp.post(query, {}, await this.getHeaders())
    return resultAxios?.data
  }

  async createRevision(id: string) {
    const query = `/api/v1/entities/${id}/revisions`
    const resultAxios = await this.apiClientPhp.post(query, {}, await this.getHeaders())
    return resultAxios?.data
  }

  async getEntities(
    page: number = 1,
    per_page: number = 20,
    filters = {} as { schema_type: string; search: string; status: string },
  ) {
    const query = `/api/v1/entities?page=${page}&per_page=${per_page}`
    const schemaTypeFilter = filters?.schema_type ? `&filter[schema_type]=${filters?.schema_type}` : ''
    const searchFilter = filters?.search ? `&filter[search]=${filters?.search}` : ''
    const statusFilter = filters?.status ? `&filter[status]=${filters?.status}` : ''
    const resultAxios = await this.apiClientPhp.get(
      query + searchFilter + schemaTypeFilter + statusFilter,
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async globalEntitiesSearch(page: number = 1, per_page: number = 20, search: string) {
    search = encodeURIComponent((search ?? '').trim())
    const query = `/api/v1/entities/global-search?page=${page}&per_page=${per_page}`
    const searchFilter = search ? `&filter[search]=${search}` : ''
    const resultAxios = await this.apiClientPhp.get(query + searchFilter, await this.getHeaders())
    return resultAxios?.data
  }

  async updateEntity(id: string, data: unknown) {
    const resultAxios = await this.apiClientPhp.put(`/api/v1/entities/${id}`, data, await this.getHeaders())
    return resultAxios?.data
  }

  async updateSupplier(id: string, data: unknown) {
    const resultAxios = await this.apiClientPhp.put(`/api/v1/suppliers/${id}`, data, await this.getHeaders())
    return resultAxios?.data
  }

  async updateProject(id: string, data: unknown) {
    const resultAxios = await this.apiClientPhp.put(`/api/v1/projects/${id}`, data, await this.getHeaders())
    return resultAxios?.data
  }

  async updateBom(id: string, data: unknown) {
    const resultAxios = await this.apiClientPhp.put(`/api/v1/boms/${id}`, data, await this.getHeaders())
    return resultAxios?.data
  }

  async updateEdge(data: unknown) {
    const resultAxios = await this.apiClientPhp.put('/api/v1/entities/update-edge', data, await this.getHeaders())
    return resultAxios?.data
  }

  async removeEntityFromView(id: string, view_id: string, relationship_name: string) {
    const resultAxios = await this.apiClientPhp.delete(
      `/api/v1/entities/${id}/remove-from-view?view_id=${view_id}&relationship_name=${relationship_name}`,
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async removeEntity(id: string) {
    const resultAxios = await this.apiClientPhp.delete(`/api/v1/entities/${id}`, await this.getHeaders())
    return resultAxios?.data
  }

  async removeBom(id: string | number) {
    const resultAxios = await this.apiClientPhp.delete(`/api/v1/boms/${id}`, await this.getHeaders())
    return resultAxios?.data
  }

  async unlinkEntities(
    source_id: string,
    target_id: string,
    relationshipName: string,
    project_uuid?: string,
    variability_id?: string,
  ) {
    let queryParams = `?from_uuid=${source_id}&to_uuid=${target_id}&relationship_name=${relationshipName}`
    if (project_uuid) queryParams = `${queryParams}&project_id=${project_uuid}`

    if (variability_id) {
      queryParams = `${queryParams}&variability_id=${variability_id}`
    }

    const resultAxios = await this.apiClientPhp.delete(`/api/v1/entities/unlink${queryParams}`, await this.getHeaders())
    return resultAxios?.data
  }

  async linkEntities(data: {
    from_uuid: string
    to_uuid: string
    relationship_name: string
    attributes: []
    project_id?: string
  }) {
    const resultAxios = await this.apiClientPhp.post('/api/v1/entities/link', data, await this.getHeaders())
    return resultAxios?.data
  }

  async linkEntitiesBulk(data: unknown) {
    const resultAxios = await this.apiClientPhp.post('api/v1/entities/bulk-link', data, await this.getHeaders())
    return resultAxios?.data
  }

  async switchActiveRelationship(
    id: string,
    payload: { to_uuid: string; relationship_name: string; project_id: string },
  ) {
    const resultAxios = await this.apiClientPhp.post(
      `/api/v1/entities/${id}/switch-active-relationship`,
      payload,
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async addVariantToEntity(entityUuid: string, newVariantUuid: string, projectUuid: string) {
    let uri = `/api/v1/entities/${entityUuid}/variability/${newVariantUuid}`
    if (projectUuid) {
      uri = `${uri}?project_id=${projectUuid}`
    }

    const resultAxios = await this.apiClientPhp.post(uri, {}, await this.getHeaders())
    return resultAxios?.data
  }

  async removeVariantFromEntity(entityUuid: string, variantUuid: string, projectUuid: string) {
    let uri = `/api/v1/entities/${entityUuid}/variability/${variantUuid}`
    if (projectUuid) {
      uri = `${uri}?project_id=${projectUuid}`
    }

    const resultAxios = await this.apiClientPhp.delete(uri, await this.getHeaders())
    return resultAxios?.data
  }

  // Deprecated please use getVariability
  async getVariabilityConfigurations(id: string) {
    if (!id) return []

    const resultAxios = await this.apiClientPhp.get(
      `/api/v1/entities/${id}/variability-configurations`,
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async getVariability(entityUuid: string, related_type: string, relationship_name: string, project_id?: string) {
    const resultAxios = await this.apiClientPhp.get(
      `/api/v1/entities/${entityUuid}/related?related_type=${related_type}&relationship_name=${relationship_name}${
        project_id ? `&project_id=${project_id}` : ''
      }`,
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async getTree(payload: {
    entity_uuid: string
    view_id: string
    variability_id: string | undefined
    project_uuid: string
    depth: number | undefined
    percentage_view: '' | '100' | '150'
    include: { todo: boolean } | undefined
  }): Promise<GraphData> {
    const variabilityId = payload.variability_id ? `&variability_id=${payload.variability_id}` : ''
    const projectUuid = payload.project_uuid ? `&project_id=${payload.project_uuid}` : ''
    const percentageView = payload.percentage_view ? `&percentage_view=${payload.percentage_view}` : ''
    const includeTodo = payload.include?.todo ? `&include=todo` : ''
    const depth = payload.depth ? `&depth=${payload.depth}` : '&depth=3'
    const params = `${variabilityId}${percentageView}${includeTodo}${projectUuid}${depth}`

    const query = `api/v1/entities/${payload.entity_uuid}/tree?view_id=${payload.view_id}${params}`
    const resultAxios = await this.apiClientPhp.get(query, await this.getHeaders())
    return resultAxios?.data
  }

  // Deprecated
  async getGraph(payload: {
    entity_uuid: string
    view_id: string
    variability_id: string | undefined
    project_uuid: string
    depth: number
    percentage_view: '' | '100' | '150'
    include: { todo: boolean } | undefined
  }): Promise<GraphData> {
    const variabilityId = payload.variability_id ? `&variability_id=${payload.variability_id}` : ''
    // const projectUuid = payload.project_uuid ? `&project_id=${payload.project_uuid}` : ''
    // const percentageView = payload.percentage_view ? `&percentage_view=${payload.percentage_view}` : ''
    // const includeTodo = payload.include?.todo ? `&include=todo` : ''
    // const depth = payload.depth ? `&depth=${payload.depth}` : '&depth=10'
    // const params = `${variabilityId}${percentageView}${includeTodo}${projectUuid}${depth}`
    const params = `${variabilityId}`

    // const query = `api/v1/entities/${payload.entity_uuid}/graph?view_id=${payload.view_id}${params}`
    // const query = `/api/v1/boms/179f8cd0-4ce4-43f5-9a34-3f71324e8877/tree?view_id=53bbeff4-0d7c-48ec-a28a-532f809fa0c0`
    const query = `/api/v1/boms/${payload.entity_uuid}/tree?view_id=${payload.view_id}${params}`
    const resultAxios = await this.apiClientPhp.get(query, await this.getHeaders())
    return resultAxios?.data
  }

  async getBomAsTree(bom_id: string, view_id: string, variability_id?: string) {
    const variabilityParam = variability_id ? `&variability_id=${variability_id}` : ''
    const query = `api/v1/boms/${bom_id}/tree?view_id=${view_id}${variabilityParam}`
    const request = getRawRequest<GraphData>(this.apiClientPhp, await this.getHeaders())
    const { data } = await request(query)
    return data
  }

  async getBomAsTable(payload: {
    bom_id: string
    view_id: string
    variability_id?: string
  }): Promise<{ columns: []; rows: [] }> {
    const variabilityId = payload.variability_id ? `&variability_id=${payload.variability_id}` : ''
    const params = `${variabilityId}`

    const query = `api/v1/boms/${payload.bom_id}/table?view_id=${payload.view_id}${params}`
    const resultAxios = await this.apiClientPhp.get(query, await this.getHeaders())
    return resultAxios?.data
  }

  async getEntityWithRelationships(entity_id: string, view_id: string, depth = 1) {
    const query = `api/v1/entities/${entity_id}/graph?view_id=${view_id}&depth=${depth}`
    const resultAxios = await this.apiClientPhp.get(query, await this.getHeaders())
    return resultAxios?.data
  }

  async getAvailableAttributes(
    sort = 'name',
    filters?: { 'filter[id]': string; 'filter[name]': string; 'filter[type]': string },
    page = 1,
    per_page = 500,
  ) {
    const filterParams = filters
      ? `${filters['filter[id]'] ? `&filter[id]=${filters['filter[id]']}` : ''}${filters['filter[name]'] ? `&filter[name]=${filters['filter[name]']}` : ''
      }${filters['filter[type]'] ? `&filter[type]=${filters['filter[type]']}` : ''}`
      : ''
    const query = `/api/v1/attributes?sort=name&sort=${sort}&page=${page}&per_page=${per_page}${filterParams}`

    const request = getPaginatedRequest<Attribute[]>(this.apiClientPhp, await this.getHeaders())
    const { data } = await request(query)
    return data
  }

  async getAttribute(id: string): Promise<unknown> {
    const query = `/api/v1/attributes/${id}`
    const resultAxios = await this.apiClientPhp.get(query, await this.getHeaders())
    return resultAxios?.data
  }

  async getViews(includeDeleted = false) {
    const request = getPaginatedRequest<View[]>(this.apiClientPhp, await this.getHeaders())
    const { data } = await request(`/api/v1/views?include_deleted=${includeDeleted}`)
    return data
  }

  async getView(id: string) {
    const request = getRequest<View>(this.apiClientPhp, await this.getHeaders())
    const { data } = await request(`/api/v1/views/${id}?include_deleted=true`)
    return data
  }

  async createView(payload: CreateView) {
    const request = postRequest<View, CreateView>(this.apiClientPhp, await this.getHeaders())
    const { data } = await request('/api/v1/views', payload)
    return data
  }

  async updateView(id: string, payload: UpdateView) {
    const request = patchRequest<View, UpdateView>(this.apiClientPhp, await this.getHeaders())
    const { data } = await request(`/api/v1/views/${id}`, payload)
    return data
  }

  async deleteView(id: string) {
    const request = deleteRequest<void>(this.apiClientPhp, await this.getHeaders())
    const { data } = await request(`/api/v1/views/${id}`)
    return data
  }

  async restoreView(id: string) {
    const resultAxios = await this.apiClientPhp.put(`/api/v1/views/${id}/restore`, {}, await this.getHeaders())
    return resultAxios?.data
  }

  async createEntityType(payload: CreateEntitySchema) {
    const request = postRequest<EntitySchema, CreateEntitySchema>(this.apiClientPhp, await this.getHeaders())
    const { data } = await request('/api/v1/entity-types', payload)
    return data
  }

  async getEntityTypes(includeDeleted = false) {
    const request = getPaginatedRequest<EntitySchema[]>(this.apiClientPhp, await this.getHeaders())
    const { data } = await request(
      `/api/v1/entity-types?sort=name&include=catalogAttributes,bomAttributes&per_page=1000&include_deleted=${includeDeleted}`,
    )
    return data
  }

  async getEntityType(id: string) {
    const request = getRequest<EntitySchema>(this.apiClientPhp, await this.getHeaders())
    const { data } = await request(`/api/v1/entity-types/${id}`)
    return data
  }

  async updateEntityType(id: string, payload: object) {
    const request = patchRequest<EntitySchema, UpdateEntitySchema>(this.apiClientPhp, await this.getHeaders())
    const { data } = await request(`/api/v1/entity-types/${id}`, payload)
    return data
  }

  async deleteEntityType(id: string) {
    const request = deleteRequest<void>(this.apiClientPhp, await this.getHeaders())
    const { data } = await request(`/api/v1/entity-types/${id}`)
    return data
  }

  async restoreEntityType(id: string) {
    const request = putRequest<EntitySchema, unknown>(this.apiClientPhp, await this.getHeaders())
    const { data } = await request(`/api/v1/entity-types/${id}/restore`, {})
    return data
  }

  async attachAttributeToEntitytype(entityTypeId: string, attributeId: string) {
    const resultAxios = await this.apiClientPhp.put(
      `/api/v1/entity-types/${entityTypeId}/catalog-attributes/${attributeId}`,
      {},
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async detachAttributeFromEntitytype(entityTypeId: string, attributeId: string) {
    const resultAxios = await this.apiClientPhp.delete(
      `/api/v1/entity-types/${entityTypeId}/catalog-attributes/${attributeId}`,
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async attachBomAttributeToEntitytype(entityTypeId: string, attributeId: string) {
    const resultAxios = await this.apiClientPhp.put(
      `/api/v1/entity-types/${entityTypeId}/bom-attributes/${attributeId}`,
      {},
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async detachBomAttributeFromEntitytype(entityTypeId: string, attributeId: string) {
    const resultAxios = await this.apiClientPhp.delete(
      `/api/v1/entity-types/${entityTypeId}/bom-attributes/${attributeId}`,
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async syncBomAttributeToEntitytype(entityTypeId: string, attributes: []) {
    const resultAxios = await this.apiClientPhp.post(
      `/api/v1/entity-types/${entityTypeId}/bom-attributes`,
      { bom_attributes: attributes },
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async syncCatalogAttributeToEntitytype(entityTypeId: string, attributes: []) {
    const resultAxios = await this.apiClientPhp.post(
      `/api/v1/entity-types/${entityTypeId}/catalog-attributes`,
      { catalog_attributes: attributes },
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async deleteRelationshipType(id: string) {
    const resultAxios = await this.apiClientPhp.delete(`/api/v1/relationships/${id}`, await this.getHeaders())
    return resultAxios?.data
  }

  async getPossibleRelationships(fromSchemaType: string, toSchemaType: string) {
    const resultAxios = await this.apiClientPhp.get(
      `/api/v1/entity-types/show-possible-links?from_entity_type_name=${fromSchemaType}&to_entity_type_name=${toSchemaType}`,
      await this.getHeaders(),
    )
    return resultAxios?.data
  }
  async getRelationshipsTypes(includeDeleted = false) {
    const resultAxios = await this.apiClientPhp.get(
      `/api/v1/relationships?sort=name&include=attributes&per_page=1000&include_deleted=${includeDeleted}`,
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async getRelationshipType(id: string) {
    const resultAxios = await this.apiClientPhp.get(`/api/v1/relationships/${id}`, await this.getHeaders())
    return resultAxios?.data
  }

  async attachAttributeToRelationshipType(relationshipId: string, attributeId: string) {
    const resultAxios = await this.apiClientPhp.put(
      `/api/v1/relationships/${relationshipId}/attributes/${attributeId}`,
      {},
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async detachAttributeFromRelationshipType(relationshipId: string, attributeId: string) {
    const resultAxios = await this.apiClientPhp.delete(
      `/api/v1/relationships/${relationshipId}/attributes/${attributeId}`,
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async getActivityLogs(id: string, include = '', page: number = 0, per_page: number = 1000) {
    const request = getPaginatedRequest<ActivityLog[]>(this.apiClientPhp, await this.getHeaders())
    const { data } = await request(
      `/api/v1/activity-logs?filter[linked_id]=${id}&include=${include}&per_page=${per_page}&page=${page}`,
    )
    return data
  }

  async getProductConfigurations() {
    const resultAxios = await this.apiClientPhp.get(
      '/api/v1/entities?attribute[schema_type]=ProductConfiguration&per_page=200',
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async overlayViewPreview(bom_id: string, source_view_id: string, destination_view_id: string) {
    const request = getRawRequest<PreviewSyncBomResponse>(this.apiClientPhp, await this.getHeaders())
    const query = `/api/v1/boms/${bom_id}/sync-preview?source_view_id=${source_view_id}&destination_view_id=${destination_view_id}`
    const { data } = await request(query)
    return data
  }

  async overlayViewApply(payload: SyncBomRequest) {
    const request = postRequest<SyncBomResponse, SyncBomRequest>(this.apiClientPhp, await this.getHeaders())
    const { data } = await request(`/api/v1/boms/${payload.bom_id}/sync`, payload)
    return data
  }

  async updateRelationshipType(id: string, payload: object) {
    const resultAxios = await this.apiClientPhp.patch(`/api/v1/relationships/${id}`, payload, await this.getHeaders())
    return resultAxios?.data
  }

  async updateAttribute(id: string, payload: UpdateAttribute) {
    const request = putRequest<Attribute, UpdateAttribute>(this.apiClientPhp, await this.getHeaders())
    const { data } = await request(`/api/v1/attributes/${id}`, payload)
    return { data }
  }

  async createAttribute(payload: CreateAttribute) {
    const request = postRequest<Attribute, CreateAttribute>(this.apiClientPhp, await this.getHeaders())
    const { data } = await request('/api/v1/attributes', payload)
    return data
  }

  async deleteAttribute(id: string) {
    const request = deleteRequest<void>(this.apiClientPhp, await this.getHeaders())
    const { data } = await request(`/api/v1/attributes/${id}`)
    return data
  }

  async createRelationship(payload: object) {
    const resultAxios = await this.apiClientPhp.post('/api/v1/relationships', payload, await this.getHeaders())
    return resultAxios?.data
  }

  async getAllIssuesForEntity(entityId: string) {
    const resultAxios = await this.apiClientPhp.get(
      `/api/v1/issues?filter[issueable_id]=${entityId}&sort=+created_at`,
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async getIssuesForEntity(entityId: string) {
    const resultAxios = await this.apiClientPhp.get(
      `/api/v1/issues?filter[issueable_id]=${entityId}&sort=+created_at&filter[status]=open`,
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async getIssueById(id: string) {
    const resultAxios = await this.apiClientPhp.get(`/api/v1/issues/${id}`, await this.getHeaders())
    return resultAxios?.data
  }

  async updateIssue(id: string, issue: object) {
    const resultAxios = await this.apiClientPhp.put(`/api/v1/issues/${id}`, issue, await this.getHeaders())
    return resultAxios?.data
  }

  async sendMessageToIssue(id: string, message: string) {
    const resultAxios = await this.apiClientPhp.post(
      `/api/v1/issues/${id}/messages`,
      { message },
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async getReports() {
    const resultAxios = await this.apiClientPhp.get('/api/v1/reports', await this.getHeaders())
    return resultAxios?.data
  }

  async getReport(id: string) {
    const resultAxios = await this.apiClientPhp.get(`/api/v1/reports/${id}`, await this.getHeaders())
    return resultAxios?.data
  }

  async getReportRuns(id: string) {
    const resultAxios = await this.apiClientPhp.get(`/api/v1/reports/${id}/runs`, await this.getHeaders())
    return resultAxios?.data
  }

  async getReportRunsData(id: string, runId: string) {
    const resultAxios = await this.apiClientPhp.get(`/api/v1/reports/${id}/runs/${runId}`, await this.getHeaders())
    return resultAxios?.data
  }

  async postReportRun(id: string, data: unknown) {
    const resultAxios = await this.apiClientPhp.post(`/api/v1/reports/${id}/run`, data, await this.getHeaders())
    return resultAxios?.data
  }

  async getNotifications(per_page: number, page: number) {
    const resultAxios = await this.apiClientPhp.get(
      `/api/v1/notifications?per_page=${per_page}&page=${page}`,
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async getUnreadNotifications(per_page: number, page: number) {
    const resultAxios = await this.apiClientPhp.get(
      `/api/v1/notifications/unread?per_page=${per_page}&page=${page}`,
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async readNotification(id: string) {
    const resultAxios = await this.apiClientPhp.put(`/api/v1/notifications/${id}/read`, {}, await this.getHeaders())
    return resultAxios?.data
  }
  async getKPIS(options: string = '') {
    const resultAxios = await this.apiClientPhp.get(`/api/v1/kpis/issues-over-time?${options}`, await this.getHeaders())
    return resultAxios?.data
  }
  async getKPISIssuesTimeToClose(options: string = '') {
    const resultAxios = await this.apiClientPhp.get(
      `/api/v1/kpis/issue-time-to-close?${options}`,
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async listIssues(title: string, status: string, level: string, page: number, perPage: number) {
    const resultAxios = await this.apiClientPhp.get(
      `/api/v1/issues?include=attachments,messages,collaborators&filter[title]=${title}&filter[status]=${status}&filter[level]=${level}&page=${page}&per_page=${perPage}`,
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async getUserById(id: string) {
    const resultAxios = await this.apiClientPhp.get(`/api/v1/users/${id}`, await this.getHeaders())
    return resultAxios?.data
  }

  async createUser(payload: object) {
    const resultAxios = await this.apiClientPhp.post(`/api/v1/users`, payload, await this.getHeaders())
    return resultAxios?.data
  }

  async updateUser(id: string, payload: object) {
    const resultAxios = await this.apiClientPhp.put(`/api/v1/users/${id}`, payload, await this.getHeaders())
    return resultAxios?.data
  }

  async deleteUser(id: string | null) {
    const resultAxios = await this.apiClientPhp.delete(`/api/v1/users/${id}`, await this.getHeaders())
    return resultAxios?.data
  }

  async restoreUser(id: string | null, payload: object) {
    const resultAxios = await this.apiClientPhp.post(`/api/v1/users/${id}/restore`, payload, await this.getHeaders())
    return resultAxios?.data
  }

  async addRelationshipConnection(payload: object) {
    const resultAxios = await this.apiClientPhp.post(
      `/api/v1/entity-types/attach-by-name`,
      payload,
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async deleteRelationshipConnection(payload: object) {
    const resultAxios = await this.apiClientPhp.post(
      `/api/v1/entity-types/detach-by-name`,
      payload,
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async bulkDeleteEntities(payload: object) {
    const resultAxios = await this.apiClientPhp.post(`/api/v1/entities/bulk-delete`, payload, await this.getHeaders())
    return resultAxios?.data
  }

  async getUserByFirebaseUid(id: string) {
    const resultAxios = await this.apiClientPhp.get(`/api/v1/users?filter[firebase_uid]=${id}`, await this.getHeaders())
    return resultAxios?.data
  }

  async getLoggedInUserInformation() {
    const resultAxios = await this.apiClientPhp.get(`/api/v1/users/me`, await this.getHeaders()).catch(error => {
      return error
    })
    if (resultAxios?.response?.status >= 400) {
      return { error: 'Unauthorized' }
    }
    return resultAxios?.data
  }

  async forgotPassword(payload: object) {
    const resultAxios = await this.apiClientPhp.post(`/api/v1/users/forgot-password`, payload, await this.getHeaders())
    return resultAxios?.data
  }

  async resetPassword(payload: object) {
    const resultAxios = await this.apiClientPhp.post(`/api/v1/users/reset-password`, payload, await this.getHeaders())
    return resultAxios?.data
  }

  async getClientSettings(): Promise<ClientSettings> {
    const resultAxios = await this.apiClientPhp.get(`/api/v1/settings/global`, await this.getHeaders())
    return resultAxios?.data
  }

  async getProjects(page: number = 0, per_page: number = 20, filters: Filters = {}) {
    const query = `/api/v1/projects?page=${page}&per_page=${per_page}&filter[search]=${filters?.name}&filter[status]=${filters?.status}`
    const resultAxios = await this.apiClientPhp.get(query, await this.getHeaders())
    return resultAxios?.data
  }

  async createProject(payload: object) {
    const resultAxios = await this.apiClientPhp.post(`/api/v1/projects`, payload, await this.getHeaders())
    return resultAxios?.data
  }

  async getBoms(page: number = 0, per_page: number = 20, filters: { name?: string; status?: string } = {}) {
    const query = `/api/v1/boms?page=${page}&per_page=${per_page}&filter[search]=${filters?.name}&filter[status]=${filters?.status}`
    const resultAxios = await this.apiClientPhp.get(query, await this.getHeaders())
    return resultAxios?.data
  }

  async createBom(payload: object) {
    const resultAxios = await this.apiClientPhp.post(`/api/v1/boms`, payload, await this.getHeaders())
    return resultAxios?.data
  }

  async uploadPublicAsset(file: File) {
    const payload = new FormData()
    payload.append('file', file)

    const resultAxios = await this.apiClientPhp.post(
      `/api/v1/assets/public`,
      payload,
      await this.getHeaders('multipart/form-data'),
    )
    return resultAxios?.data
  }

  async getTodosCount() {
    const resultAxios = await this.apiClientPhp.get(`/api/v1/todos/counts`, await this.getHeaders())
    return resultAxios?.data
  }

  async getTodos(
    include = ['comments'],
    filters: { project_id?: string; entity_id?: string; view_id?: string; page?: number; perPage?: number } = {
      project_id: '',
      entity_id: '',
      view_id: '',
    },
  ) {
    let filtersString =
      filters && (filters?.project_id || filters?.entity_id)
        ? filters?.project_id
          ? `&filter[project_id]=${filters?.project_id}&filter[view_id]=${filters?.view_id}`
          : `&filter[entity_id]=${filters?.entity_id}`
        : ''

    filtersString = `${filtersString}&filter[resolved]=false&page=${filters.page ?? 1}&per_page=${
      filters.perPage ?? 15
    }`

    const request = getPaginatedRequest<Feedback[]>(this.apiClientPhp, await this.getHeaders())

    const { data } = await request(`/api/v1/todos?include=${include.join(',')}${filtersString}`)
    return data
  }

  // Feedbacks - todos & comments

  async createTodo(payload: NewFeedback, include = ['comments']) {
    const request = postRequest<Feedback, NewFeedback>(this.apiClientPhp, await this.getHeaders())

    const { data } = await request(`/api/v1/todos?include=${include.join(',')}`, payload)
    return data
  }

  async createTodoComment(todoId: string, payload: NewFeedbackComment) {
    const request = postRequest<FeedbackComment, NewFeedbackComment>(this.apiClientPhp, await this.getHeaders())
    const { data } = await request(`/api/v1/todos/${todoId}/comments`, payload)
    return data
  }

  async deleteTodoComment(todoId: string, commentId: string) {
    const request = deleteRequest<FeedbackComment>(this.apiClientPhp, await this.getHeaders())
    const { data } = await request(`/api/v1/todos/${todoId}/comments/${commentId}`)
    return data
  }

  async updateTodoComment(todoId: string, commentId: string, payload: FeedbackComment) {
    const request = putRequest<FeedbackComment, FeedbackComment>(this.apiClientPhp, await this.getHeaders())
    const { data } = await request(`/api/v1/todos/${todoId}/comments/${commentId}`, payload)
    return data
  }

  async updateTodo(todoId: string, payload: UpdateFeedback) {
    const request = putRequest<Feedback, UpdateFeedback>(this.apiClientPhp, await this.getHeaders())
    const { data } = await request(`/api/v1/todos/${todoId}`, payload)
    return data
  }

  async deleteTodo(todoId: string) {
    const request = deleteRequest<Feedback>(this.apiClientPhp, await this.getHeaders())
    const { data } = await request(`/api/v1/todos/${todoId}`)
    return data
  }

  // File implies documents
  async getDocuments(entityId: string, search: string = '', page: number = 1, perPage: number = 15) {
    search = encodeURIComponent(search.trim())
    const resultAxios = await this.apiClientPhp.get(
      `/api/v1/documents?filter[search]=${search}&filter[documentables.documentable_id]=${entityId}&page=${page}&per_page=${perPage}`,
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async createDocument(name: string = '', description = '', tags: string[] = [], file: File) {
    const payload = new FormData()
    payload.append('name', name)
    payload.append('description', description)
    tags.forEach((tag: string) => {
      payload.append('tags[]', tag.toString())
    })
    payload.append('file', file)

    const resultAxios = await this.apiClientPhp.post(
      `/api/v1/documents`,
      payload,
      await this.getHeaders('multipart/form-data'),
    )
    return resultAxios?.data
  }

  async updateDocument(documentId: string, payload: object) {
    const resultAxios = await this.apiClientPhp.patch(
      `/api/v1/documents/${documentId}`,
      payload,
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async createEntityDocument(entityId: string, name: string = '', description = '', tags: string[] = [], file: File) {
    const payload = new FormData()
    payload.append('name', name)
    payload.append('description', description)
    tags.forEach((tag: string) => {
      payload.append('tags[]', tag.toString())
    })
    payload.append('file', file)

    const resultAxios = await this.apiClientPhp.post(
      `/api/v1/entities/${entityId}/documents`,
      payload,
      await this.getHeaders('multipart/form-data'),
    )
    return resultAxios?.data
  }

  // New version of document
  async replaceDocument(documentId: string, file: File) {
    const payload = new FormData()
    payload.append('file', file)

    const resultAxios = await this.apiClientPhp.post(
      `/api/v1/documents/${documentId}/replace`,
      payload,
      await this.getHeaders('multipart/form-data'),
    )
    return resultAxios?.data
  }

  async deleteDocument(documentId: string) {
    const resultAxios = await this.apiClientPhp.delete(`/api/v1/documents/${documentId}`, await this.getHeaders())
    return resultAxios?.data
  }

  async getSuppliers(page: number = 0, per_page: number = 20, filters: Filters = {}) {
    let query = `/api/v1/suppliers?page=${page}&per_page=${per_page}`
    if (filters?.name) {
      query = query += `&filter[search]=${filters?.name}`
    }
    if (filters?.status) {
      query = query += `&filter[status]=${filters?.status}`
    }

    const resultAxios = await this.apiClientPhp.get(query, await this.getHeaders())
    return resultAxios?.data
  }

  async searchSupplier(page: number = 0, perPage: number = 20, search: string = '') {
    let query = `/api/v1/suppliers/search?page=${page}&per_page=${perPage}`
    if (search) {
      search = encodeURIComponent(search.trim())
      query += `&filter[search]=${search}`
    }

    const resultAxios = await this.apiClientPhp.get(query, await this.getHeaders())
    return resultAxios?.data
  }

  async createSupplier(payload: unknown) {
    const resultAxios = await this.apiClientPhp.post(`/api/v1/suppliers`, payload, await this.getHeaders())
    return resultAxios?.data
  }

  async getSupplier(supplierId: string) {
    const resultAxios = await this.apiClientPhp.get(`/api/v1/suppliers/${supplierId}`, await this.getHeaders())
    return resultAxios?.data
  }

  async getEntitiesBySupplier(supplierId: string, search: string = '', page: number = 1, perPage: number = 15) {
    search = encodeURIComponent(search.trim())
    const resultAxios = await this.apiClientPhp.get(
      `/api/v1/suppliers/${supplierId}/entities?filter[search]=${search}&&page=${page}&per_page=${perPage}`,
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async linkEntityToSupplier(supplierId: string, entityId: string, payload: Record<PropertyKey, unknown>) {
    const resultAxios = await this.apiClientPhp.post(
      `/api/v1/suppliers/${supplierId}/entities/${entityId}`,
      payload,
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async unlinkEntityToSupplier(supplierId: string, entityId: string, relationshipName: string) {
    const resultAxios = await this.apiClientPhp.post(
      `/api/v1/suppliers/${supplierId}/entities/${entityId}/delete`,
      {
        relationship_name: relationshipName,
      },
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async getSuppliersByEntity(entityId: string, search: string = '', page: number = 1, perPage: number = 15) {
    search = encodeURIComponent(search.trim())
    const resultAxios = await this.apiClientPhp.get(
      `/api/v1/entities/${entityId}/suppliers?filter[search]=${search}&&page=${page}&per_page=${perPage}`,
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async deleteSupplier(supplierId: string) {
    const resultAxios = await this.apiClientPhp.delete(`/api/v1/suppliers/${supplierId}`, await this.getHeaders())
    return resultAxios?.data
  }

  async updateEntitySupplierLink(entityId: string, supplierId: string, payload: unknown) {
    const resultAxios = await this.apiClientPhp.put(
      `/api/v1/suppliers/${supplierId}/entities/${entityId}`,
      payload,
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async uploadFile(file: File) {
    const payload = new FormData()
    payload.append('file', file)
    const resultAxios = await this.apiClientPhp.post(
      `api/v1/etl/upload`,
      payload,
      await this.getHeaders('multipart/form-data'),
    )
    return resultAxios?.data
  }

  async getProjectsByEntity(entityId: string, search: string = '', page: number = 1, perPage: number = 15) {
    search = encodeURIComponent(search.trim())
    const resultAxios = await this.apiClientPhp.get(
      `/api/v1/entities/${entityId}/projects?filter[search]=${search}&&page=${page}&per_page=${perPage}`,
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async getBomsByEntity(entityId: string, search: string = '', page: number = 1, perPage: number = 15) {
    search = encodeURIComponent(search.trim())
    const resultAxios = await this.apiClientPhp.get(
      `/api/v1/entities/${entityId}/boms?filter[search]=${search}&&page=${page}&per_page=${perPage}`,
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async getEntityUsers(entityId: string) {
    const resultAxios = await this.apiClientPhp.get(`/api/v1/entities/${entityId}/share`, await this.getHeaders())
    return resultAxios?.data
  }

  async getBomUsers(bomId: string) {
    const resultAxios = await this.apiClientPhp.get(`/api/v1/boms/${bomId}/share`, await this.getHeaders())
    return resultAxios?.data
  }

  async shareEntity(entityId: string, userId: string, role: number) {
    const resultAxios = await this.apiClientPhp.post(
      `/api/v1/entities/${entityId}/share`,
      {
        users: [
          {
            user_id: userId,
            role: role,
          },
        ],
      },
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async shareBom(bomId: string, userId: string, role: number, views: unknown) {
    const resultAxios = await this.apiClientPhp.post(
      `/api/v1/boms/${bomId}/share`,
      {
        user_uuid: userId,
        role: role,
        views: views,
      },
      await this.getHeaders(),
    )

    return resultAxios?.data
  }

  async revokeEntity(entityId: string, userId: string) {
    const resultAxios = await this.apiClientPhp.delete(
      `/api/v1/entities/${entityId}/share/${userId}`,
      await this.getHeaders(),
    )
    return resultAxios?.data
  }

  async revokeBom(bomId: string, userId: string) {
    const resultAxios = await this.apiClientPhp.delete(`/api/v1/boms/${bomId}/share/${userId}`, await this.getHeaders())
    return resultAxios?.data
  }

  async readAllNotifications() {
    const resultAxios = await this.apiClientPhp.post(`/api/v1/notifications/read-all`, {}, await this.getHeaders())
    return resultAxios?.data
  }

  async restoreRelationship(id: string) {
    const resultAxios = await this.apiClientPhp.put(`/api/v1/relationships/${id}/restore`, {}, await this.getHeaders())
    return resultAxios?.data
  }
}
