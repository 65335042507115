<script setup lang="ts">
import { formatDistance } from 'date-fns'
import { debounce } from 'lodash'
import { useKeyupHandler } from '~/composables/useKeyupHandler'
import { isForvia } from '~/helpers/growthbook/growthbook'
import { growthBookKey } from '~/modules/growthbook'
import { apiStore } from '~/stores/api'
import { entityTypesStore } from '~/stores/entity_types'
import type { ToastList } from '~/types/toast'
import type { Entity } from '~/types/view-elements'
import { getDateFnsLocaleFromBrowserLanguage } from '~/utils/formatter'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const api = apiStore().getApiClient

const state = reactive({
  selectedEntities: [] as string[],
  currentRevisionState: 'draft' as 'inspect' | 'draft' | 'review',
  isImportModalOpen: false,
  isCreatingElement: '' as '' | 'node' | 'edge',
  isLoading: false,
  currentPage: 1,
  hasFetchingNext: false,
  observer: null,
  supportedIntersectObserver: false,
  isReachedAtLast: false,
  filters: {
    schemaType: '',
    search: '',
    status: route?.query?.status || '',
  },
  toast: inject('toast') as ToastList,
  isForvia: false,
  selectedEntitiesForDelete: [],
  hasDeleting: false,
  hasDeleteModalOpen: false,
  isEntityCreationModalOpen: false,
  imagePath: null,
  isFullscreenImageModalOpen: false,
  canCreateEntity: false,
  selectAll: false,
})

const data = reactive({
  entities: [] as Entity[],
})

function openImportModal() {
  state.isImportModalOpen = true
}

function closeImportModal() {
  state.isImportModalOpen = false
}

function openDeleteModal() {
  state.hasDeleteModalOpen = true
}

function closeDeleteModal() {
  state.hasDeleteModalOpen = false
}

function openEntityAdditionModal() {
  state.isEntityCreationModalOpen = true
}

function closeEntityCreationModal() {
  state.isEntityCreationModalOpen = false
}

function filterBySchemaType(value: string) {
  state.selectAll = false
  state.selectedEntitiesForDelete = []
  state.filters.schemaType = value
  state.currentPage = 1
  loadData()
}

function filterByStatus(value: string) {
  state.selectAll = false
  state.selectedEntitiesForDelete = []
  state.filters.status = value
  state.currentPage = 1
  loadData()
}

const filterBySearch = debounce(() => {
  state.selectAll = false
  state.selectedEntitiesForDelete = []
  state.currentPage = 1
  loadData()
}, 500)

function registerObserver() {
  let options = {
    rootMargin: '0px',
    root: null,
  }

  state.observer = new window.IntersectionObserver((entries, _) => {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0) {
        loadPaginatedData(state.currentPage, 15)
      }
    })
  }, options)

  let target = document.querySelector('#issueObserver')
  if (target) {
    state.observer.observe(target)
  }
}

function unRegisterObserver() {
  let target = document.querySelector('#issueObserver')
  if (target && state.observer) {
    state.observer.unobserve(target)
    state.observer = null
  }
}

function goToEntity(row: Entity) {
  if (!row.id && !row.auth.can.view) {
    return
  }

  router.push(`/catalog/${row.uuid}`)
}

function unshiftNewEntity(entity: Entity) {
  data.entities.unshift(entity)
}

function checkOrUncheck(entity: any) {
  entity.is_checked = !entity.is_checked
  if (entity.is_checked) {
    // Add to remove array
    state.selectedEntitiesForDelete.push(entity.id)
  } else {
    // Remove from array
    const idx = state.selectedEntitiesForDelete.indexOf(entity.id)
    state.selectedEntitiesForDelete.splice(idx, 1)
  }
}

async function deleteEntities() {
  state.hasDeleting = true
  try {
    await api.bulkDeleteEntities({
      entity_uuids: state.selectedEntitiesForDelete,
    })

    data.entities = data.entities.filter(entity => !entity.is_checked)
    state.hasDeleteModalOpen = false
    state.selectedEntitiesForDelete = []
    state.selectAll = false
    state.toast.success(t('global.success'), t('entities.bulk_delete_success'))
  } catch (error) {
    state.toast.error(t('global.error'), error.response?.data?.message || error.toString())
  } finally {
    state.hasDeleting = false
  }
}

async function loadPaginatedData(page: number, perPage: number = 15) {
  if (state.hasFetchingNext || state.isReachedAtLast) return

  state.isLoading = true
  state.hasFetchingNext = true

  try {
    const [response] = await Promise.all([
      api.getEntities(page, perPage, {
        schema_type: state.filters.schemaType,
        search: state.filters.search,
        status: state.filters.status,
      }),
      entityTypesStore().loadEntityTypes(),
    ])

    if (response?.data) {
      const d = response?.data.map(d => {
        if (state.selectAll) {
          state.selectedEntitiesForDelete.push(d.id)
        }
        Object.assign(d, { is_checked: state.selectAll })
        return d
      })

      data.entities.push(...d)

      if (response.meta.current_page >= response.meta.last_page) state.isReachedAtLast = true

      if (response?.auth?.can?.create) {
        state.canCreateEntity = response?.auth?.can?.create
      }

      if (data.entities.length <= 0) {
        state.selectedEntitiesForDelete = []
        state.selectAll = false
      }

      state.currentPage += 1
      state.hasFetchingNext = false
      state.isLoading = false
    }
  } catch (error) {
    router.push('/')
    state.canCreateEntity = false
    state.toast.error(t('global.error'), error.response?.data?.message || error.toString())
  } finally {
    state.isLoading = false
  }
}

function openFullScreenImageModal(imagePath: string) {
  if (!imagePath) {
    return
  }

  state.imagePath = imagePath
  state.isFullscreenImageModalOpen = true
}

function closeFullScreenImageModal() {
  state.isFullscreenImageModalOpen = false
}

async function loadData() {
  data.entities = []
  loadPaginatedData(1, 15)
}

function selectOrUnselectAllEntities() {
  if (!state.selectAll && state.selectedEntitiesForDelete.length > 0) {
    state.selectAll = false
  } else {
    if (!state.selectAll && state.selectedEntitiesForDelete.length === 0) {
      state.selectAll = true
    } else {
      if (state.selectAll) {
        state.selectAll = false
      } else {
        state.selectAll = true
      }
    }
  }

  data.entities.forEach(entity => {
    entity.is_checked = state.selectAll
    if (entity.is_checked) {
      state.selectedEntitiesForDelete.push(entity.id)
    } else {
      state.selectedEntitiesForDelete = []
    }
  })
}

/** Permission functions start */
const canCreateEntity = computed(() => {
  return state.canCreateEntity
})
/** Permission functions end */

onBeforeMount(async () => {
  const growthBookInjectable = inject(growthBookKey)
  const growthBook = await growthBookInjectable?.init()
  state.isForvia = (await isForvia(growthBook)) || false
})

const handleEscapeKey = () => {
  closeDeleteModal()
  closeEntityCreationModal()
}

useKeyupHandler(handleEscapeKey)

onMounted(() => {
  if (!window.IntersectionObserver) {
    state.supportedIntersectObserver = false
  } else {
    state.supportedIntersectObserver = true
  }

  loadData()
})

onBeforeUnmount(() => {
  unRegisterObserver()
})

watch(
  () => data.entities.length,
  () => {
    registerObserver()
  },
)

watch(
  () => state.isReachedAtLast,
  newVal => {
    if (newVal) {
      unRegisterObserver()
    }
  },
)
</script>

<template>
  <div class="flex flex-1 flex-col gap-2 px-4 md:px-6 lg:px-8 mt-4 overflow-y-scroll">
    <div class="flex gap-2 mb-4">
      <div class="flex gap-2 mr-auto">
        <LibraryEntitiesFilters
          :schema-type="state.filters.schemaType"
          @filterSchemaType="filterBySchemaType"
          :status="state.filters.status"
          @filter-status="filterByStatus"
        />
        <OSearchBar
          v-model="state.filters.search"
          :placeholder="$t('global.search')"
          class="!w-96"
          @update:model-value="filterBySearch"
          data-test-id="search"
        />
      </div>

      <button
        class="btn-danger gap-1.5 h-full"
        v-if="state.selectedEntitiesForDelete.length > 0"
        @click="openDeleteModal"
        data-test-id="delete-entities"
      >
        <div i="carbon-trash-can" />
        {{ $t('global.delete') }}
      </button>

      <div v-if="data.entities.length > 0">
        <button
          class="btn-secondary gap-1.5 h-full"
          data-test-id="select-unselect-entities"
          @click="selectOrUnselectAllEntities"
        >
          <div v-if="state.selectedEntitiesForDelete.length <= 0" class="flex items-center">
            <div i="carbon-checkbox" />
            <div class="pl-2">{{ $t('global.select') }}</div>
          </div>
          <div v-else class="flex items-center">
            <div i="carbon-checkbox-indeterminate" />
            <div class="pl-2">{{ $t('global.unselect') }}</div>
          </div>
        </button>
      </div>
      <button class="btn-secondary gap-1.5 h-full h-fit" @click="openImportModal" v-if="canCreateEntity">
        <div i="carbon-upload" />
        {{ $t('global.import') }}
      </button>

      <button class="btn-primary h-full" @click="openEntityAdditionModal()" v-if="canCreateEntity">
        <div i="carbon-intent-request-create" />
        {{ $t('global.new') }}
      </button>
    </div>

    <div class="grid grid-cols-12 px-2.5 gap-1 text-[#475467] text-center text-sm">
      <div scope="col" class="col-span-1">
        <button class="text-left mr-2 opacity-30" @click="selectOrUnselectAllEntities" v-if="data.entities.length > 0">
          <div v-if="state.selectedEntitiesForDelete.length > 0" i="carbon-checkbox-indeterminate" />
          <div v-else i="carbon-checkbox" />
        </button>
      </div>
      <div scope="col" class="col-span-3 flex items-center gap-2 text-left">
        <p class="w-10">
          {{ $t('global.type') }}
        </p>
        {{ t('global.name') }}
      </div>
      <div scope="col" class="text-left col-span-1">{{ t('global.image') }}</div>
      <div scope="col" class="text-left col-span-1">{{ t('global.status') }}</div>
      <div scope="col" class="text-left col-span-3">{{ t('global.description') }}</div>
      <div scope="col" class="col-span-2">{{ t('global.last_updated') }}</div>
      <div scope="col">{{ t('global.action', 2) }}</div>
    </div>
    <div class="flex flex-col">
      <template v-for="(entity, index) in data.entities" :key="entity.id" v-if="data.entities.length">
        <button
          :class="{ 'cursor-not-allowed': !entity.auth.can.view }"
          class="group grid grid-cols-12 items-center gap-1 text-[#344054] text-center text-sm bg-white hover:bg-gray-50 border border-[#F2F4F7] not-first:border-t-0 first:rounded-t last:rounded-b"
          :data-test-id="`entity-${entity.id}`"
          @click="goToEntity(entity)"
        >
          <div class="col-span-1 flex justify-center">
            <button
              v-if="entity.auth.can.delete"
              role="checkbox"
              :data-test-id="`entities-select-${index}`"
              class="text-left pl-4 pr-2"
              :class="entity.is_checked ? 'opacity-100' : 'opacity-30'"
              @click.stop.prevent="() => checkOrUncheck(entity)"
            >
              <div v-if="entity.is_checked" i="carbon-checkbox-checked-filled" class="text-[#3B82F6]" />
              <div v-else i="carbon-checkbox" />
            </button>
          </div>

          <div class="col-span-3 flex items-center gap-2 py-1">
            <EntityIcon :entity="entity" class="group-hover:bg-[#DEE9FF]" :tooltip="true" />
            <p class="text-[#1D2939] text-sm truncate" :title="entity.name">
              {{ entity.name }}
            </p>
          </div>

          <div class="col-span-1 max-h-10 overflow-hidden rounded" @click="openFullScreenImageModal(entity?.image)">
            <img :src="entity.image" v-if="entity?.image" class="object-contain w-[42px] h-auto aspect-square" />
          </div>

          <div class="flex items-center gap-2 col-span-1">
            <PublishingStatusTag :status="entity.status" />
          </div>

          <div class="flex items-center gap-2 col-span-3">
            <div class="text-left text-sm text-[#475467] line-clamp-2 break-all truncate" :title="entity.description">
              {{ entity.description }}
            </div>
          </div>

          <div class="text-sm text-center whitespace-nowrap col-span-2">
            <template v-if="entity.updated_at">
              {{
                formatDistance(new Date(entity.updated_at), new Date(), {
                  addSuffix: true,
                  locale: getDateFnsLocaleFromBrowserLanguage(),
                })
              }}
            </template>
          </div>

          <button
            class="p-1 mx-auto border border-transparent text-sm text-[#475467] transition hover:border-[#E6E6E5] col-span-1"
            @click.stop.prevent="goToEntity(entity)"
          >
            <div i="carbon-arrow-up-right" />
          </button>
        </button>
      </template>

      <template v-if="!state.isLoading && data.entities.length <= 0">
        <div class="text-center py-6 text-black" data-test-id="entities_empty_list">{{ t('entities.empty_list') }}</div>
      </template>

      <div id="issueObserver" />

      <div v-if="state.isLoading" class="my-10">
        <Loader />
      </div>
    </div>
    <GraphElementImportModal
      :open="state.isImportModalOpen"
      import-from="catalog"
      @close="closeImportModal"
      @import-success="loadData"
    />
    <EntityCreationModal
      :open="state.isEntityCreationModalOpen"
      @close="closeEntityCreationModal"
      @create="unshiftNewEntity"
    />

    <!-- Delete Entity -->
    <OModal :open="state.hasDeleteModalOpen" data-test-id="delete-modal">
      <template #content>
        <div class="flex flex-col gap-1">
          <h2 class="text-xl font-semibold">
            {{ $t('global.confirm_delete') }}
          </h2>
          <p>
            {{ $t('entities.bulk_delete_title') }}
          </p>
          <span
            class="mt-4 rounded-md bg-red-50 px-2 py-1 text-s font-medium text-red-700 ring-1 ring-inset ring-red-600/10"
          >
            <b>Warning:&nbsp;</b>{{ $t('entities.bulk_delete_description') }}
          </span>
        </div>
      </template>

      <template #footer>
        <div class="flex justify-end w-full gap-4">
          <button class="btn-secondary" :disabled="state.hasDeleting" @click="closeDeleteModal">
            {{ t('global.cancel') }}
          </button>
          <button
            class="btn-danger bg-red-500 text-white"
            autofocus
            :disabled="state.hasDeleting"
            @click="deleteEntities"
          >
            {{ state.hasDeleting ? t('global.deleting') : t('global.delete') }}
          </button>
        </div>
      </template>
    </OModal>

    <!-- Image modal -->
    <OModal :open="state.isFullscreenImageModalOpen" @close="closeFullScreenImageModal">
      <template #content>
        <img :src="state.imagePath" />
      </template>

      <template #footer>
        <div class="flex justify-end w-full gap-4">
          <button class="btn-primary w-full" @click="closeFullScreenImageModal">
            {{ $t('global.close') }}
          </button>
        </div>
      </template>
    </OModal>
  </div>
</template>
