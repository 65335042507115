<script setup lang="ts">
import { useHead } from '@unhead/vue'
import { reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import { loadUser } from '~/controllers/authentication'
import AnalyticsHelper from '~/helpers/AnalyticsHelper'
import { getClients } from '~/helpers/growthbook/growthbook'
import { hotReload, versionToNumber } from '~/helpers/UtilsHelper'
import { growthBookKey } from '~/modules/growthbook'
import { orgStore } from '~/stores/org'
import { getNavigatorLanguage } from '~/utils/formatter'
import { clearStores } from './controllers/stores'
import { globalStore } from './stores/global'
import { userStore } from './stores/user'
import { unwrapRouteParam } from './utils/route'
const growthBookInjectable = inject(growthBookKey)

const analyticsHelper = new AnalyticsHelper()
const route = useRoute()
const router = useRouter()

const { locale } = useI18n()

const VERSION = import.meta.env.PACKAGE_VERSION

useHead({
  title: `Cognyx - ${VERSION}`,
  meta: [{ name: 'description', content: `Cognyx - ${VERSION}` }],
})

const appData = reactive({
  loading: false,
  interval_ctx: null as unknown,
  show_modal: false,
  input_buffer: '',
  last_input_timestamp: null,
  last_detected_newline_timestamp: null,
  newVersionAvailable: false,
})

// load user local
const locale_iso: string = getNavigatorLanguage() // navigator.language.substring(0, 2)
locale.value = locale_iso
// load User Info

async function init() {
  analyticsHelper.logAction('INIT_APP')
  if (route.meta?.requiresAuth) appData.loading = true
  const userResult = await loadUser()
  if (userResult?.error) {
    router.push('/auth/login')
  }
  if (typeof window !== 'undefined') {
    appData.interval_ctx = setInterval(checkVersion, 1000 * 60 * 5) // ask for new version very 5 min
    checkVersion()
  }

  const goTo = unwrapRouteParam(route.query?.go)
  if (goTo) router.push(`/${goTo}`)

  // Growthbook Init
  const growthBook = await growthBookInjectable?.init()
  if (growthBook) {
    // Add extra attrs
    growthBook?.setAttributes({
      app: window.location.hostname,
    })

    // Load orgs
    let clients = (await getClients(growthBook)) || []
    orgStore().loadOrg(clients)
  }

  appData.loading = false
}

async function hotReloadApp() {
  await hotReload()
}
/* checkVersion */
async function checkVersion() {
  const frontVersion = versionToNumber(VERSION)
  const serverVersion = versionToNumber(VERSION) // TODO get actual server api version
  if (frontVersion < serverVersion && !appData.newVersionAvailable) {
    appData.show_modal = true
    appData.newVersionAvailable = true
  }
}

onMounted(() => {
  init()
})

onUnmounted(() => {
  clearStores()
})
//
</script>

<template>
  <CheckVersionModal
    :open="appData.show_modal"
    @close="appData.show_modal = false"
    @load_latest_version="hotReloadApp"
    :new-version-available="appData.newVersionAvailable"
  />

  <router-view v-if="!appData.loading && userStore().stores_loaded" />

  <div v-else class="flex flex-col gap-2 w-full h-full justify-center items-center">
    <div class="text-[#373FEF] mx-auto">
      <img src="/assets/logo_small.svg" alt="logo" class="w-14 mb-8 animate-spin" />
    </div>

    <div class="text-xl text-slate text-center text-600 pl-2">
      {{ globalStore().stores_loading_msg }}
    </div>

    <div class="absolute bottom-20 align-bottom cursor-pointer text-white" @click="hotReloadApp">
      <div class="text-3xl" i="carbon-reset" />
      <span>{{ VERSION }}</span>
    </div>
  </div>
</template>
