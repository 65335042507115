<script setup lang="ts">
import tippy from 'tippy.js'
import { v4 as uuidv4 } from 'uuid'

const props = defineProps({
  status: {
    type: String as PropType<'draft' | 'published' | 'archived' | 'approved' | 'rejected' | 'canceled'>,
    required: true,
  },
  reduced: {
    type: Boolean,
    required: false,
    default: false,
  },
  class: {
    type: String,
    required: false,
    default: '',
  },
})

const { t } = useI18n()

const state = reactive({
  randUuid: uuidv4(),
})

const getStatusIcon = computed(() => {
  if (props.status === 'draft') return 'carbon-edit'
  if (props.status === 'published') return 'carbon-checkmark'
  if (props.status === 'approved') return 'carbon-checkmark'
  if (props.status === 'rejected') return 'carbon-close'
  if (props.status === 'archived') return 'carbon-close'
})

function setupTippy() {
  nextTick(() => {
    tippy(`#publishing-status-tag-${state.randUuid}`, {
      content: t(`global.status_${props.status}`),
      placement: 'top',
    })
  })
}

watch(
  () => props.reduced,
  () => {
    if (props.reduced) {
      setupTippy()
    }
  },
  { immediate: true },
)
</script>

<template>
  <div
    :id="`publishing-status-tag-${state.randUuid}`"
    class="flex items-center h-fit gap-1.5 text-xs rounded-full"
    :class="[
      props.class,
      props.reduced ? 'p-0.5' : 'px-1.5 py-0.25',
      {
        'bg-[#EAECF0] text-[#667085]': props.status === 'draft',
        'bg-[#E5FBF6] text-[#0F766E]': props.status === 'published' || props.status === 'approved',
        'bg-[#FEF3F2] text-[#B42318]': props.status === 'canceled' || props.status === 'rejected',
      },
    ]"
  >
    <div :i="getStatusIcon" class="h-3 w-3" />

    <span v-if="!props.reduced && status">
      {{ $t(`global.status_${status}`) }}
    </span>
  </div>
</template>
