<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { entityTypesStore } from '~/stores/entity_types'
import { Status } from '../../utils/entity'
import { getEntityStatusClass } from '../../utils/css'

const props = defineProps({
  schemaType: {
    type: String,
    required: true,
    default: '',
  },
  status: {
    type: String,
    required: true,
    default: '',
  },
})

const route = useRoute()
const router = useRouter()

const emit = defineEmits<{
  (e: 'filter-schema-type', value: string): void
  (e: 'filter-status', value: string): void
}>()

const state = reactive({
  isSchemaTypeSelectionOpen: false,
  hoveringSchemaType: false,
  isStatusSelectionOpen: false,
  hoveringStatus: false,
  fontSizeId: `title-size-${Math.random().toString(36).substring(7)}`,
  schemaTypeSelectionId: `type-selection-${Math.random().toString(36).substring(7)}`,
  statusSelectionId: `type-selection-${Math.random().toString(36).substring(7)}`,
  schemaType: {
    searchedValue: '',
  },
})

const filteredSchemaTypes = computed(() => {
  const searchValue = state.schemaType.searchedValue?.toLowerCase() || ''
  const filteredTypes = entityTypesStore().getEntityTypesAsOptionsWithNameAsValue.filter(schemaType => {
    if (schemaType.name === 'Project') {
      return false
    }

    if (!searchValue) {
      return true
    }

    const displayName = schemaType?.display_name?.toLowerCase()
    const name = schemaType?.name?.toLowerCase()
    return displayName?.includes(searchValue) || name?.includes(searchValue)
  })

  // Sort the filtered types alphabetically by display_name, falling back to name if display_name is missing
  return filteredTypes.sort((a, b) =>
    (a.display_name?.toLowerCase() || a.name.toLowerCase()).localeCompare(
      b.display_name?.toLowerCase() || b.name.toLowerCase(),
    ),
  )
})

function openSchemaTypeSelectionPopOver() {
  state.isSchemaTypeSelectionOpen = true
}

function openStatusPopOver() {
  state.isStatusSelectionOpen = true
}

function resetPopOvers() {
  state.isSchemaTypeSelectionOpen = false
  state.isStatusSelectionOpen = false
}

function hoverExpandable(type: 'schemaType' | 'status', delay: number = 300) {
  if (type === 'schemaType') {
    state.hoveringSchemaType = true
    setTimeout(() => (state.isSchemaTypeSelectionOpen = state.hoveringSchemaType), delay)
  }
  if (type === 'status') {
    state.hoveringStatus = true
    setTimeout(() => (state.isStatusSelectionOpen = state.hoveringStatus), delay)
  }
}

function unHoverExpandable(type: 'schemaType' | 'status') {
  if (type === 'schemaType') {
    state.hoveringSchemaType = false
    setTimeout(() => (state.isSchemaTypeSelectionOpen = state.hoveringSchemaType), 1)
  }
  if (type === 'status') {
    state.hoveringStatus = false
    setTimeout(() => (state.isStatusSelectionOpen = state.hoveringStatus), 1)
  }
}

function setSchemaType(value: string, close: () => void) {
  // Same click means RESET ALL
  if (props.schemaType == value) {
    emit('filter-schema-type', '')
  } else {
    emit('filter-schema-type', value)
  }
  resetPopOvers()
  close()
}

function setStatus(value: string, close: () => void) {
  // Same click means RESET ALL
  const query = { ...route.query }

  if (props.status == value) {
    query.status = ''
    router.replace({ name: String(route.name), params: route.params, query: query })
    emit('filter-status', '')
  } else {
    query.status = value
    router.replace({ name: String(route.name), params: route.params, query: query })
    emit('filter-status', value)
  }
  resetPopOvers()
  close()
}
</script>

<template>
  <Popover v-slot="{ close, open }" class="relative flex-shrink-0">
    <PopoverButton class="btn-secondary gap-1.5 h-full" :class="open ? '!border-[#373FEF]' : '!hover:text-secondary'">
      <div i="carbon-filter" :class="open ? '!text-secondary' : ''" />
      {{ $t('global.filter_by') }}
      <PublishingStatusTag v-if="props.status" :status="props.status" class="ml-1" />
    </PopoverButton>

    <transition
      enter-active-class="transition ease-out duration-200"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition ease-in duration-150"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <PopoverPanel class="absolute top-10 z-10 flex w-screen max-w-70">
        <div
          class="w-50 flex-auto overflow-hidden rounded bg-white text-sm leading-6 ring-1 ring-gray-900/5"
          style="box-shadow: 0 2px 6px 2px rgba(60, 64, 67, 0.15)"
        >
          <!-- Expandable pop over example -->

          <div class="flex flex-col py-2">
            <div class="group bg-white">
              <button
                class="flex w-full items-center gap-2 px-3 py-1.5 text-[#43474F] text-sm cursor-pointer"
                :class="state.isSchemaTypeSelectionOpen ? 'bg-[#DADCE080]' : 'group-hover:bg-[#DADCE080]'"
                @click="openSchemaTypeSelectionPopOver"
                @mouseover="hoverExpandable('schemaType')"
                @mouseleave="unHoverExpandable('schemaType')"
              >
                <div i="carbon-cube" />
                {{ $t('global.entity_type') }}
                <div i="carbon-caret-right" class="ml-auto" />
              </button>
              <div
                :id="state.schemaTypeSelectionId"
                class="absolute top-0 ml-69.5 p-1 pt-0 max-h-md bg-white rounded border-1 border-l ring-gray-900/5 overflow-y-scroll"
                :class="state.isSchemaTypeSelectionOpen ? 'w-80 z-30' : 'opacity-0 w-0'"
                @mouseover="hoverExpandable('schemaType', 2)"
                @mouseleave="unHoverExpandable('schemaType')"
              >
                <div class="relative flex flex-col w-full">
                  <div class="sticky top-0 mb-2 pt-1 bg-white z-31">
                    <FormKit
                      type="text"
                      v-model="state.schemaType.searchedValue"
                      :placeholder="$t('library.search_schema_type')"
                      prefix-icon="carbon-search"
                      inner-class="ring-none border-b border-[#E6E6E5] rounded-none"
                      input-class="!text-sm"
                    />
                  </div>

                  <button
                    v-for="schemaType in filteredSchemaTypes"
                    :key="schemaType.name"
                    class="flex w-full items-center gap-2 px-4 py-2 rounded hover:bg-[#E0E8FF80] text-[#40403F] cursor-pointer"
                    :class="{
                      'bg-[#E0E8FF80] rounded-none': props.schemaType === schemaType.name,
                    }"
                    @click="setSchemaType(schemaType.name, close)"
                  >
                    <div :i="schemaType?.icon ?? 'carbon-cube'" :style="{ color: schemaType?.color }" />
                    {{ schemaType.display_name || schemaType.name }}
                  </button>
                </div>
              </div>
            </div>
            <div class="group bg-white">
              <button
                class="flex w-full items-center gap-2 px-3 py-1.5 text-[#43474F] text-sm cursor-pointer"
                :class="state.isStatusSelectionOpen ? 'bg-[#DADCE080]' : 'group-hover:bg-[#DADCE080]'"
                @click="openStatusPopOver"
                @mouseover="hoverExpandable('status')"
                @mouseleave="unHoverExpandable('status')"
              >
                <div i="carbon-checkmark-outline" />
                {{ $t('global.status') }}
                <div i="carbon-caret-right" class="ml-auto" />
              </button>
              <div
                :id="state.statusSelectionId"
                class="absolute top-0 ml-69.5 p-1 pt-0 max-h-md bg-white rounded border-1 border-l ring-gray-900/5"
                :class="state.isStatusSelectionOpen ? 'w-80 z-30' : 'opacity-0 w-0'"
                @mouseover="hoverExpandable('status', 2)"
                @mouseleave="unHoverExpandable('status')"
              >
                <div class="relative flex flex-col w-full mt-1">
                  <button
                    v-for="status in Status"
                    :key="status.name"
                    class="flex w-full items-center gap-2 px-4 py-2 rounded hover:bg-[#E0E8FF80] text-[#40403F] cursor-pointer"
                    :class="{
                      'bg-[#E0E8FF80] rounded-none': props.status === status.value,
                    }"
                    @click="setStatus(status.value, close)"
                  >
                    <div :i="status?.icon ?? 'carbon-cube'" />
                    {{ status.name }}
                  </button>
                </div>
              </div>
            </div>

            <div class="group bg-white">
              <button
                class="flex w-full items-center gap-2 px-3 py-1.5 text-[#43474F] text-sm cursor-not-allowed opacity-60"
                :class="false ? 'bg-[#DADCE080]' : 'group-hover:bg-[#DADCE080]'"
              >
                <div i="carbon-data-table-reference" />
                {{ $t('global.attributes') + ' (' + $t('global.coming_soon') + ')' }}
                <div i="carbon-caret-down" class="ml-auto" />
              </button>
            </div>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>
